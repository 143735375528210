import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SidebarTemplate from "components/templates/Sidebar";
import ListGrid from "components/organisms/ListGrid";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import FormDepartament from "components/templates/Departaments/FormDepartment";
import { LockOpenOutlined } from '@material-ui/icons'
import { makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CustomizedMenus from 'components/organisms/ListGrid/CustomizedMenus';
import DepartmentService from "services/department.service";
import { formatBRL } from "helpers/money";
import { ContainerPage, TopContainerPage, ContentPage, FooterPage, TitlePage, FloatButton } from "../../atoms/StyledsBase/styledIndex";

import "./styles.sass";

const useStyles = makeStyles({
  root: {
    backgroundColor: "red",
    color: props => props.color
  },
  demo: {
    borderRadius: "16px",
    boxShadow: "-5px 5px 30px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "#ffffff"
  },
  peperSize: {
    color: "#909090"
  }
});

const DepartamentsTemplate = ({
  children,
  departaments,
  searchConfig,
  setSearchConfig,
  onRefresh,
  ...props
}) => {
  const [toogleScreen, setToogleScreen] = useState(false);
  const [departamentEdit, setDepartamentEdit] = useState(false);
  const classes = useStyles(props);

  useEffect(() => {
    if (toogleScreen == false) {
      setDepartamentEdit(false);
    }
  }, [toogleScreen])

  const DataTable = () => (
    {
      header: [
        { flex: 2, label: "Name do Departamento" },
        { flex: 2, label: "Responsável" }, { flex: 1, label: "Limite mensal" },
        { flex: 1, label: "Editar", flexDirection: 'column' },
        { flex: 1, label: "Ações", flexDirection: 'column' }
      ],
      rows: MountRowsTable(departaments)
    }
  )

  const MountRowsTable = (departaments) => {
    return (departaments || []).reduce((rows, item) => {
      rows.push([
        { flex: 2, label: item.name },
        { flex: 2, label: (item.leader || '') },
        { flex: 1, label: `${formatBRL(item.monthlyLimit) || formatBRL('0000')}` },
        { flex: 1, children: () => renderEditButton(item.id), flexDirection: 'column' },
        { flex: 1, children: () => (<CustomizedMenus id={item.id} items={renderMenuActions(item)} />), flexDirection: 'column' },
      ])
      return rows
    }, [])
  }

  const renderEditButton = (id) => (<EditIcon className={classes.peperSize} onClick={() => {
    setDepartamentEdit(id);
    setToogleScreen(true);
  }} />)

  const renderMenuActions = (row) => {
    return [
      {
        label: "Excluir", confirm: true,
        element: () => (<LockOpenOutlined style={{ color: '#909090' }} />),
        onClick: (id) => remove(row)
      }
    ]
  }

  const remove = async (department) => {
    await DepartmentService.remove(department.id)
    onRefresh();
  }

  return (
    <SidebarTemplate>
      <ContainerPage>
        {toogleScreen ? (<FormDepartament onRefresh={onRefresh} departamentEdit={departamentEdit} setToogleScreen={setToogleScreen} />) : (
          <>
            <FloatButton>
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => setToogleScreen(true)}
              >
                <AddIcon />
              </Fab>
            </FloatButton>
            <TopContainerPage>
              <TitlePage>Departamentos</TitlePage>
            </TopContainerPage>
            <ContentPage>
              <ListGrid context="Departamento" dataTable={DataTable()} />
            </ContentPage>
          </>
        )}
      </ContainerPage>
    </SidebarTemplate>
  );
};

DepartamentsTemplate.propTypes = {
  itemsPerPageOptions: PropTypes.array,
  searchConfig: PropTypes.object,
  setSearchConfig: PropTypes.func
};

DepartamentsTemplate.defaultProps = {
  itemsPerPageOptions: [20, 30],
  searchConfig: {},
  setSearchConfig: () => { }
};

export default DepartamentsTemplate;
