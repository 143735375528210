import React, { useEffect, useState } from 'react';
import SidebarTemplate from 'components/templates/Sidebar';
import CompanyService from 'services/company.service';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  Tooltip,
  colors
} from '@material-ui/core';
import { useAuth } from 'context';
import { withRouter } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AirplayIcon from '@material-ui/icons/Airplay';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import DialogConfirmation from './DialogConfirmation';
import Field from 'components/atoms/Field';

const companyStatus = {
  1: 'Lead',
  2: 'Inconsistência nos documentos',
  3: 'Ativa',
  4: 'Pendencia de pagamento',
  5: 'Bloqueada',
  6: 'Excluída'
};

const colorBadge = {
  Lead: colors.orange['500'],
  'Inconsistência nos documentos': colors.orange['500'],
  Ativa: colors.green['500'],
  Bloqueada: colors.red['500'],
  Excluída: colors.red['500']
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: 20,
    paddingRight: '2%'
  },
  card: {
    maxWidth: 222,
    margin: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 208
  },
  cardContent: {
    display: 'flex',
    minWidth: 190,
    flexDirection: 'column'
  },
  typography: {
    marginTop: 10
  },
  cardActions: {
    justifyContent: 'space-between'
  },
  media: {
    height: 140
  },
  filter: {
    width: '100%',
    marginTop: 10,
    '& label.Mui-focused': {
      color: '#99a6b7'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#99a6b7'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#99a6b7'
      },
      '&:hover fieldset': {
        borderColor: '#99a6b7'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#16aae7'
      }
    }
  }
}));

const Companies = props => {
  const [companies, setCompanies] = useState([]);
  const [companiesFiltered, setCompaniesFiltered] = useState([]);
  const classes = useStyles();
  const { setAuth, auth } = useAuth();
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState({});

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const { data } = await CompanyService.findAll();
    setCompanies(data);
    setCompaniesFiltered(data);
  };

  const changeTokenCompany = async company => {
    try {
      const { data } = await CompanyService.changeTokenCompany(company.id);
      setAuth({ ...auth, company: company, token: data.token });
      props.history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const ToogleDialogConfirmation = (() => {
    const title = { block: 'Bloquear', unblock: 'Desbloquear' };
    let open = false;

    return (action, company = {}) => {
      open = !open;
      setOpenDialogConfirmation({
        open: open,
        message: `${company.name}`,
        action: () => blockAndUnblockCompany(action, company),
        title: title[action],
        closeDialog: () => ToogleDialogConfirmation()
      });
    };
  })();

  const blockAndUnblockCompany = async (action, company) => {
    try {
      const actions = {
        block: () => CompanyService.block(company.id),
        unblock: () => CompanyService.unblock(company.id)
      };
      await actions[action]();
      getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = e => {
    const filter = companies.filter(company => {
      let cnpj = new RegExp(e.target.value, 'i').test(company.cnpj);
      let name = new RegExp(e.target.value, 'i').test(company.name);
      return cnpj || name;
    });
    setCompaniesFiltered(filter);
    if (companiesFiltered.length === 0) setCompaniesFiltered(companies);
  };

  return (
    <SidebarTemplate>
      <Grid container spacing={0}>
        <DialogConfirmation {...openDialogConfirmation} />
          <Grid item xs={12} className={classes.root}>
            <Field
              label="Filtrar Empresas"
              onChange={handleFilter}
              className={classes.filter}
            />
          </Grid>
        <Grid container direction="row" className={classes.root}>
          {companiesFiltered.map((company, index) => (
            <Card className={classes.card} key={index}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h4">
                  {company.name}
                </Typography>
                <Typography
                  className={classes.typography}
                  variant="body2"
                  color="textSecondary"
                  component="span"
                >
                  <b>Cnpj: </b> {company.cnpj}
                </Typography>
                <Typography
                  style={{ marginTop: 20 }}
                  className={classes.typography}
                  variant="body2"
                  color="textSecondary"
                  component="span"
                >
                  <span
                    style={{
                      padding: 6,
                      borderRadius: 4,
                      color: '#ffffff',
                      backgroundColor:
                        colorBadge[companyStatus[company.statusId]]
                    }}
                  >
                    {companyStatus[company.statusId]}
                  </span>
                </Typography>
              </CardContent>
              <CardActions disableSpacing className={classes.cardActions}>
                <Tooltip
                  title="Acessar empresa"
                  aria-label="change TokenCompany"
                >
                  <IconButton
                    onClick={() => changeTokenCompany(company)}
                    aria-label="add to favorites"
                  >
                    <AirplayIcon style={{ color: colors.green['500'] }} />
                  </IconButton>
                </Tooltip>
                {company.statusId !== 5 ? (
                  <Tooltip
                    title="Bloquear empresa?"
                    aria-label="change TokenCompany"
                  >
                    <IconButton
                      onClick={() => ToogleDialogConfirmation('block', company)}
                    >
                      <LockOutlinedIcon style={{ color: colors.red['500'] }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Desbloquear empresa?"
                    aria-label="change TokenCompany"
                  >
                    <IconButton
                      onClick={() =>
                        ToogleDialogConfirmation('unblock', company)
                      }
                    >
                      <LockOpenIcon style={{ color: colors.green['500'] }} />
                    </IconButton>
                  </Tooltip>
                )}
              </CardActions>
            </Card>
          ))}
        </Grid>
      </Grid>
    </SidebarTemplate>
  );
};

export default withRouter(Companies);
