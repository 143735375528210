import { post } from 'services/api';

const LoginService = {}

LoginService.signIn = async (data) => {
  return await post({ type: 'user', service: 'login', data: data })
}

LoginService.newPassword = async (uuid, data) => {

  return await post({ type: 'user', service: `reset-password/${uuid}`, data: data })
}

LoginService.forgotPassword = async (email) => {
  return await post({ type: 'user', service: 'forgot-password', data: { email } });
}


export default LoginService