import React from "react";
import { ContainerBreadCamps, ItemBreadCamps, FormContainer, TitleForm, TitleContainerForm, ContentForm, FooterForm } from "./styledForm";
import useStyles from "components/base/stylesInputs";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const Form = (props) => {
  const classes = useStyles(props);
  let history = useHistory();

  const renderHead = () => (
    <ContainerBreadCamps style={{ 'marginLeft': '0px' }}>
      <ItemBreadCamps onClick={() => props.setToogleScreen(false)}>
        <ArrowBackIcon
          className={classes.arrow}
        />
      </ItemBreadCamps>
      <ItemBreadCamps onClick={() => history.push('/')}>
        Dashboard |
          </ItemBreadCamps>
      <ItemBreadCamps onClick={() => props.setToogleScreen(false)}>
        {props.context} |
          </ItemBreadCamps>
      <ItemBreadCamps>
        {`${props.isEdition ? 'Alterar cadastro' : 'Adicionar novo'}`}
      </ItemBreadCamps>
    </ContainerBreadCamps>
  )

  return (
    <>
      {renderHead()}
      <FormContainer>
        <ContentForm>
          <TitleContainerForm>
            <TitleForm>
              {`${props.isEdition ? 'Alterar ' : 'Adicionar novo'} ${props.context}`}
            </TitleForm>
          </TitleContainerForm>
          <form
            onSubmit={props.onSubmit}
          >
            {props.children}
            <FooterForm>
              <Grid item style={{ 'marginRight': '10px' }}>
                <Button
                  variant="outlined"
                  onClick={() => props.setToogleScreen(false)}
                  className={classes.cancelButtonStyles}>Cancelar</Button>
              </Grid>
              <Grid item style={{ 'marginRight': '0px' }}>
                <Button style={{ backgroundColor: '#308ACB' }} type="submit" color="primary" variant="contained" className={classes.buttonStyles}>
                  {props.isEdition ? 'Alterar' : 'Cadastrar'}
                </Button>
              </Grid>
            </FooterForm>
          </form>
        </ContentForm>
      </FormContainer>
    </>
  )

}
export default Form;