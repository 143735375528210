import React, { useEffect, useState } from 'react';

import DepartamentTemplate from 'components/templates/Departaments';
import DepartmentService from 'services/department.service';
import { DepartamentContext } from 'context';

const DepartamentsPage = props => {

  const [canFetchData, setCanFetchData] = useState(true);
  const [departament, setDepartaments] = useState([]);
  const [searchConfig, setSearchConfig] = useState({
    page: {
      number: 0,
      size: 20,
    },
    sort: {
      desc: false,
      field: 'name'
    }
  });

  useEffect(() => {
    if (searchConfig.search) {
      search()
    } else {
      setCanFetchData(true);
    }
  }, [searchConfig]);

  useEffect(() => {
    if (canFetchData) {
      findAll({
        searchConfig,
        setDepartaments
      });
      setCanFetchData(false);
    }
  }, [canFetchData]); // eslint-disable-line react-hooks/exhaustive-deps

  const search = () => {
    if (searchConfig.search) {
      let content = (departament || []).filter(col => {
        if (JSON.stringify(col).toUpperCase().indexOf(searchConfig.search.toUpperCase()) !== -1) {
          return col
        }
      });
      return content;
    }
    return departament;
  };

  async function findAll({ searchConfig, setDepartaments }) {
    const { data } = await DepartmentService.findAll(searchConfig);
    setDepartaments(data)
  }

  return (
    <>
      <DepartamentContext.Provider
        value={{
          departament,
          setDepartaments
        }}
      >
        <DepartamentTemplate
          departaments={search()}
          itemsPerPageOptions={[5, 10, 20, 30, 40, 50]}
          searchConfig={searchConfig}
          setSearchConfig={setSearchConfig}
          onRefresh={() => findAll({
            searchConfig,
            setDepartaments
          })}
        />
      </DepartamentContext.Provider>
    </>
  );
}

export default DepartamentsPage;
