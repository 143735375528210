import axios from 'axios';
import qs from 'qs';
import { getToken } from '../../store';
import EventEmmiter from '../../providers/event.emmiter';

export const events = new EventEmmiter();

export const api = (type = 'user') => {
  const paths = {
    user: process.env.REACT_APP_API_USERS_BASEPATH,
    trips: process.env.REACT_APP_API_TRIPS_BASEPATH,
    fleet: 'https://fleet-dot-turbi-22388.appspot.com/'
  };

  const API = axios.create({
    baseURL: paths[type],
    headers: {
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Origin': '*',
      //'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json'
    }
  });

  // Return all http/s codes to response
  API.interceptors.response.use(undefined, err => {
    const {
      config,
      response: { status }
    } = err;
    return Promise.reject({
      status: status,
      config: config,
      response: err.response
    });
  });

  // Inject header Authorization
  API.interceptors.request.use(
    config => {
      const token = getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return API;
};

export const get = async (config = {}) => {
  const { type, service, queryString } = config;

  let queryStr = '';
  if (Object.keys(queryString || []).length > 0)
    queryStr = `?${qs.stringify(queryString, { encodeValuesOnly: true })}`;

  return await wrapperRequest(api(type).get(`${service}${queryStr}`));
};

export const post = async (config = {}) => {
  const { type, service, data } = config;
  return await wrapperRequest(api(type).post(service, data));
};

export const patch = async (config = {}) => {
  const { type, service, data } = config;
  return await wrapperRequest(api(type).patch(service, data));
};

export const put = async (config = {}) => {
  const { type, service, data } = config;
  return await wrapperRequest(api(type).put(service, data));
};

export const remove = async (params = {}) => {
  const { type, uri } = params;
  return await wrapperRequest(api(type).delete(uri));
};

const wrapperRequest = async requestPromise => {
  try {
    events.emmit('onLoad', true);
    let response = await requestPromise;
    events.emmit('onLoad', false);
    return response;
  } catch (error) {
    events.emmit('onLoad', false);
    if (error.status === 401) events.emmit('onRedirect', null);
    return error;
  }
};

export default {
  api,
  get,
  post,
  remove
};
