import React, { useState, useEffect } from 'react';
import { Switch, Grid, FormControlLabel, withStyles } from '@material-ui/core';


import CompanyService from 'services/company.service'

import './styles.css';

const CustomSwitch = withStyles({
  switchBase: {
    color: '#5b616f',
    '&$checked': {
      color: '#308bcb',
    },
    '&$checked + $track': {
      backgroundColor: '#308bcb',
    },
  },
  checked: {},
  track: {},
})(Switch);

function Justification() {

  let [selected, setSelected] = useState(false)

  useEffect(() => {
    CompanyService.find()
      .then((company) => {
        setSelected(company.requireTripDescription)
      })
  }, [])

  const onChange = (event) => {
    setSelected(event.target.checked)
    CompanyService.update({ requireTripDescription: event.target.checked })
    event.preventDefault()
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <span className='header2'>Motivo de viagem</span>
        </Grid>
        <Grid item xs={12}>
          <span className='content2'>Ative os “Motivos de viagem” para que o colaborador faça uma descrição no momento da reserva.</span>
          <span style={{ opacity: '0' }}>Ative as “Motivos de viagem” para que o colaborador faça uma descrição no momento da reserva.</span>
        </Grid>
        <Grid item xs={12}>
          <span className='enable-text'>Ativar</span>

          <FormControlLabel
            control={
              <CustomSwitch
                value={selected}
                checked={selected}
                onChange={(e) => onChange(e)}
              />
            }
          />

        </Grid>

      </Grid>
    </React.Fragment>
  )

}

export default Justification