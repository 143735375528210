import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogContent, IconButton } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import empty from "components/base/svg/icons/empty.svg";
import Slide from '@material-ui/core/Slide';
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ openDialog, setOpenDialog, confirmAction, action, message }) {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmAction = () => {
    confirmAction();
    handleClose();
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="close-btn">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <h1 className="modal-dialog-header">Tem certeza? </h1>
        </DialogTitle>
        <DialogContent>
          <img
            src={empty}
            className="modal-confirm-bgImage"
            alt="confirmation screen background"
          />
          <DialogContentText>
            <span style={{fontFamily: "ProximaNova-Regular"}}>Você está prestes a <b>{action}</b> {message}</span>.
          </DialogContentText>
        </DialogContent>
        <div className="modal-btn-wrapper">
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              autoFocus
              onClick={handleClose}
              className="cancel-btn"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <span style={{fontFamily: "ProximaNova-Regular"}} className="Cancelar" onClick={() => handleClose()}>Cancelar</span>
            </Button>
            <Button
              onClick={() => handleConfirmAction()}
              autoFocus
              className="continue-btn"
              style={{ backgroundColor: "#308bcb", color: "#fff" }}
            >
              <span style={{fontFamily: "ProximaNova-Regular"}} className="Continuar">Continuar</span>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}