import React from 'react';
import PropTypes from 'prop-types';

import './styles.sass';

const Base = props => {
  return (
    <>
      {props.children}
    </>
  );
}

Base.propTypes = {
  children: PropTypes.element.isRequired
};

export default Base;
