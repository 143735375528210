import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import ThemeContainer from 'components/atoms/ThemeContainer';

const Field = props => {

  return (
    <ThemeContainer>
      <TextField
        {...props}
        error={props.error.message ? true : false}
        className={props.className}
        color="primary"
        label={props.label}
        name={props.name}
        inputRef={props.inputRef}
        type={props.type}
        variant="outlined"
      />
    </ThemeContainer>
  );
}

Field.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  inputRef: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string
}

Field.defaultProps = {
  className: "",
  error: {},
  inputRef: () => {},
  label: "",
  name: "",
  type: "text"
};

export default Field;