import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import SplitButton from 'components/atoms/SplitButton';
import WidgetBase from 'components/molecules/WidgetBase';
import Pagination from 'components/molecules/Pagination';
import { formatBRL } from 'helpers/money';
import moment from 'moment';
import './styles.sass';

const WidgetTrips = ({
  averageTicket,
  data,
  filters,
  filtersChange,
  vehicles,
  ...props
}) => {
  const handlerChangePerPage = perPage => {
    props.changePerPage(perPage);
  };

  const handlerPageNumber = page => {
    props.changePageNumber(page);
  };

  const pad2 = number => `${Number(number) < 10 ? '0' : ''}${number}`;

  return (
    <div className="widget-trips">
      <WidgetBase
        title="Viagens"
        headerAux={
          <>
            <SplitButton
              onChange={filtersChange}
              options={filters}
              option={filters[0]}
            />
          </>
        }
      >
        <div className="widget-trips__content">
          <div className="widget-trips__resume">
            <div className="widget-trips__resume__header">
              <div className="widget-trips__resume__header__content">
                Neste Período:
              </div>
              <div className="widget-trips__resume__header__content">
                {averageTicket && averageTicket.count}
                <span>
                  {averageTicket &&
                  averageTicket.count &&
                  parseInt(averageTicket.count) > 1
                    ? ' Viagens'
                    : ' Viagem'}
                </span>
              </div>
              <div className="widget-trips__resume__header__content">
                {formatBRL(averageTicket.amount)}
                <span>
                  {' '}
                  Ticket médio: {formatBRL(averageTicket.averageTicket)}
                </span>
              </div>
            </div>
            {vehicles.length < 5 ? (
              <div
                className={classNames(
                  'widget-trips__resume__content',
                  `widget-trips__resume__content--size-${vehicles.length}`
                )}
              >
                {vehicles.map((vehicle, key) => {
                  return (
                    <div key={key} className="widget-trips__resume__car">
                      <img
                        alt={vehicle.label}
                        src={vehicle.photo}
                        width="100"
                      />
                      <span className="widget-trips__resume__car__name">
                        {vehicle.label}
                      </span>
                      <span className="widget-trips__resume__car__trips">
                        {vehicle.value}&nbsp;
                        {vehicle && vehicle.value && parseInt(vehicle.value) > 1
                          ? 'viagens'
                          : 'viagem'}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="widget-trips__resume__content widget-trips__resume__content--carousel">
                <Carousel slidesPerPage={4} arrows infinite>
                  {vehicles.map((vehicle, key) => {
                    return (
                      <div key={key} className="widget-trips__resume__car">
                        <img
                          alt={vehicle.label}
                          src={vehicle.photo}
                          width="100"
                        />
                        <span className="widget-trips__resume__car__name">
                          {vehicle.label}
                        </span>
                        <span className="widget-trips__resume__car__trips">
                          {vehicle.value}&nbsp;
                          {vehicle &&
                          vehicle.value &&
                          parseInt(vehicle.value) > 1
                            ? 'viagens'
                            : 'viagem'}
                        </span>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            )}
          </div>
          <div className="widget-trips__last-trips">
            <div className="widget-trips__last-trips__title">
              Últimas viagens
            </div>
            <div className="widget-trips__last-trips__content">
              {data &&
                data.content &&
                data.content.map((trip, key) => {
                  return (
                    <div key={key} className="widget-trips__last-trips__trip">
                      <div className="widget-trips__last-trips__trip__user">
                        <div className="widget-trips__last-trips__trip__user__desc">
                          <span className="widget-trips__last-trips__trip__user__name">
                            {trip.employee}
                          </span>
                          <span className="">
                            Início:{' '}
                            {moment(trip.startedAt)
                              .utc()
                              .format('DD-MM-YY HH:mm')}
                          </span>
                          <span>
                            Fim:{' '}
                            {moment(trip.finishedAt)
                              .utc()
                              .format('DD-MM-YY HH:mm')}
                          </span>
                        </div>
                      </div>

                      <div className="widget-trips__last-trips__trip__detail">
                        <img
                          alt={trip.model}
                          src={trip.vehiclePicture}
                          width="100"
                        />
                        <div className="widget-trips__last-trips__trip__detail__desc">
                          <span className="widget-trips__last-trips__trip__detail__car">
                            {trip.model}
                          </span>
                          <div>
                            <span className="widget-trips__last-trips__trip__detail__duration">
                              {pad2(
                                Math.trunc(
                                  moment.duration(trip.duration).asHours()
                                )
                              )}
                              :{pad2(moment.duration(trip.duration).minutes())}:
                              {pad2(moment.duration(trip.duration).seconds())}
                            </span>
                            <br></br>
                            <span className="widget-trips__last-trips__trip__detail__price">
                              {trip.mileage} km por {formatBRL(trip.value)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {data && data.page ? (
            <div className="widget-trips__pagination">
              <Pagination
                changePerPage={handlerChangePerPage}
                changePageNumber={handlerPageNumber}
                itemsPerPageOptions={[1, 2, 3, 4, 5, 10]}
                page={data.page.number}
                perPage={data.page.size}
                totalItems={data.page.items}
                totalPages={data.page.range}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </WidgetBase>
    </div>
  );
};

WidgetTrips.propTypes = {
  averageTicket: PropTypes.object,
  changePerPage: PropTypes.func,
  changePageNumber: PropTypes.func,
  data: PropTypes.object,
  filters: PropTypes.array,
  filtersChange: PropTypes.func,
  vehicles: PropTypes.array
};

WidgetTrips.defaultProps = {
  averageTicket: {},
  changePerPage: () => {},
  changePageNumber: () => {},
  data: {},
  filters: [],
  filtersChange: () => {},
  vehicles: []
};

export default WidgetTrips;
