import React, { Component } from 'react';
import SidebarTemplate from 'components/templates/Sidebar';
import TripInfoPage from './FullWidthTabs';


export default class index extends Component {
    render() {
        return (
            <SidebarTemplate>
                <TripInfoPage />
            </SidebarTemplate>
        )
    }
}
