import { get, patch, post, remove } from 'services/api';

const DepartmentService = {}

DepartmentService.find = async (config) => {

    return await get({ type: 'user', service: `department/${config}`, queryString: config })
}

DepartmentService.findAll = async (config) => {

    return await get({ type: 'user', service: 'department', queryString: config })
}

DepartmentService.save = async (department) => {

    department.monthlyLimit = (department.monthlyLimit ? parseFloat(department.monthlyLimit) : null)
    return await post({ type: 'user', service: 'department', data: [department] })
}

DepartmentService.edit = async (department) => {

    department.monthlyLimit = (department.monthlyLimit ? parseFloat(department.monthlyLimit) : null)
    return await patch({ type: 'user', service: `department/${department.id}`, data: department })
}

DepartmentService.remove = async (id) => {
    let response = await remove({ type: 'user', uri: `department/${id}` })
    console.info('remove department', response)
}

export default DepartmentService