import styled from "styled-components";

export const FormContainer = styled.div`
	display: flex;
	min-width: 100%;
	margin-top: 16px;
	justify-content: flex-start;
	flex-direction: column;
	margin-right: 10px;
	box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
	border-radius: 16px;
	min-width: 627px;
`;

export const TitleContainerForm = styled.div`
    display: flex;
    min-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 3px solid #EDF0F2;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    margin-bottom: 32px;
`;

export const TitleForm = styled.h2`
    color: gray;
    padding: 1% 0% 0% 0%;
    width: 100%;
    height: 24px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
`;

export const ContentForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 32px 32px 32px;
`;

export const FooterForm = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 32px;
    margin-right: -8px;
`;

export const ContainerBreadCamps = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px
    margin-bottom: 15px
    width: 100%;
    margin-left: 30px;
`;

export const ItemBreadCamps = styled.span`
    font-weight: 500;
    word-spacing: 5px;
    cursor: pointer;
    margin-right: 10px;
    line-height: 1;
    color: #5b616f;
    font-size: 14px;
    font-weight: 600;
    font-family: ProximaNova-Extrabld;
`;