import React, { useState } from 'react';
import useForm from "react-hook-form";

import LoginService from 'services/Login';

import LoginBaseTemplate from 'components/templates/LoginBase';
import FieldPassword from 'components/atoms/FieldPassword';

import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as InfoErrorIcon } from 'components/base/svg/icons/info-error.svg';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import './style.css'


function ResetPasswordPage() {

    const [error, setErrorState] = useState(false);
    const [form, setFormState] = useState(false)
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory()
    let { uuid } = useParams();

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const onSubmit = async ({ password, confirmPassword }) => {

        if (password != confirmPassword) {
            setErrorState({ message: 'As senhas devem ser iguais' })
            return
        }

        let response = await LoginService.newPassword(uuid, { password: password })
        if (response.status != 202) {
            setErrorState({ message: response.response.data.message })
            return
        }
        setFormState({ ...form, showToast: true })
        setTimeout(() => history.push('/login'), 700)
    }

    return (
        <LoginBaseTemplate>
            <Snackbar open={form.showToast} autoHideDuration={600}>
                <Alert severity="success">
                    Cadastrado com sucesso! </Alert>
            </Snackbar>
            <div className="login-sign-in">
                <form
                    className="login-sign-in__form" onSubmit={handleSubmit(onSubmit)}>
                    <h1 className="login-sign-in__form__title">Definir senha</h1>
                    <h2 className="login-sign-in__form__desc">Registre uma nova Senha.</h2>

                    <FieldPassword
                        AriaLabelIconPassword="toggle password visibility"
                        className="login-input"
                        error={errors.password}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Preenchimento obrigatório da senha'
                            }
                        })}
                        name="password"
                        label="Digite sua senha"
                        labelWidth={120}
                    />

                    <FieldPassword
                        AriaLabelIconPassword="toggle password visibility"
                        className="login-input"
                        error={errors.password}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Preenchimento obrigatório da senha'
                            }
                        })}
                        name="confirmPassword"
                        label="Confirme sua Senha"
                        labelWidth={120}
                    />
                    <div className="login-sign-in__aux">
                        {
                            error ? (
                                <>
                                    <InfoErrorIcon className="login-sign-in__icon-error" />
                                    <div className="login-sign-in__error__container">
                                        <b className="login-sign-in__error__label">Dados Incorretos:</b>
                                        <span>{error.message}</span>
                                    </div>
                                </>
                            ) : ''
                        }
                    </div>
                    <input type="submit" className="login-btn" value="Registrar" />
                </form>
            </div>
        </LoginBaseTemplate>
    )

}
export default ResetPasswordPage;