import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

import SplitButton from 'components/atoms/SplitButton';
import WidgetBase from 'components/molecules/WidgetBase';

import './styles.sass';

const WidgetTracingChart = ({ data, filters, filtersChange, ...props }) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const seriesLocal = [];
    const labelLocal = [];
    if (data.length > 0) {
      data.forEach(item => {
        labelLocal.push(item.label);
        seriesLocal.push(item.value);
      });
    }
    setLabels(labelLocal);
    setSeries(seriesLocal);
  }, [data]);

  let colors = [
    '#009ce2',
    '#0b6e9a',
    '#011f4b',
    '#03396c',
    '#005b96',
    '#6497b1',
    '#b3cde0'
  ];
  let options = {
    colors: colors,
    dataLabels: {
      enabled: false
    },
    labels: labels,
    series: series,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true,
              color: undefined,
              formatter: function(value, { config, globals }) {
                return value;
              }
            }
          },
          size: '85%'
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
    tooltip: {
      enabled: false
    }
  };
  return (
    <div className="widget-tracing-chart">
      <WidgetBase
        title="Gráfico de corridas"
        headerAux={
          <>
            <SplitButton onChange={filtersChange} options={filters} />
          </>
        }
      >
        <Chart options={options} series={options.series} type="donut" />
      </WidgetBase>
    </div>
  );
};

WidgetTracingChart.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.array,
  filtersChange: PropTypes.func
};

WidgetTracingChart.defaultProps = {
  data: [],
  filters: [],
  filtersChange: () => {}
};

export default WidgetTracingChart;
