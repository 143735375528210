import React from 'react';
import PropTypes from 'prop-types';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const TurbiTheme = createTheme({
  palette: {
    primary: {
      main: '#3ab8f0'
    }
  }
});

const ThemeContainer = ({ children }) => {
  return <ThemeProvider theme={TurbiTheme}>{children}</ThemeProvider>;
};

ThemeContainer.propTypes = {
  children: PropTypes.element.isRequired
};

ThemeContainer.defaultProps = {
  children: <></>
};

export default ThemeContainer;
