import styled from 'styled-components';

export const ContainerNameCompany = styled.div`
    display: flex;
    flex-direction: row;
    max-height: 90px;
    justify-content: center;
    align-items: center;
`;

export const NameCompany = styled.span`
    color: #5c666f;
    margin-right: 4px;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
`;