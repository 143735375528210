import React, { useState, useImperativeHandle } from "react";
import { TableContainer, RowBodyTable, Wrapper, RowHeader, Cell, TextCell, TextHeader} from "./styled";
import NotFound from '../../templates/NoDataFound/Nodatafound';
import "./styles.sass";
import "./styles.css";

const ListGrid = ({ dataTable, context, containerStyle, cellStyle, textCellStyle }) => {

  const renderHeader = (Columns, i) => {
    let rowJsx = [];
    Object.keys((Columns || [])).forEach((key, index) =>  {
      rowJsx.push(<Cell {...Columns[key]} flexDirection={Columns[key].flexDirection} flex={Columns[key].flex} onClick={(event)=> tryEvent(Columns[key], event)}>{Columns[key].label ?<TextHeader>{Columns[key].label}</TextHeader> : renderCell(Columns[key])}</Cell>)
    });
    return (
      <RowHeader key={i}>
        {rowJsx}
      </RowHeader>
      );
  }

  const renderRows = (rows) => {
    let bodyJsx = [];
  
    Object.keys((rows || [])).forEach((key, index) =>  {
      let rowObject = rows[key];
      let rowElement = [];
      Object.keys(rowObject).forEach((Subkey, i) => {
        let object = rowObject[Subkey]
        rowElement.push(<Cell {...object} style={cellStyle} flexDirection={object.flexDirection} flex={object.flex} onClick={(event)=> tryEvent(object, event)}>{object.label ? <TextCell style={textCellStyle} first={i == 0 ? 'bold' : 'normal'}>{object.label}</TextCell>  : renderCell(object)}</Cell>)
      })
      bodyJsx.push(
        <Wrapper>
          <RowBodyTable className="RowBodyTable">
            {rowElement}
          </RowBodyTable>
        </Wrapper>)
    });
    return bodyJsx
  }

  const renderCell = (hander) => {
    if(typeof hander.children == "function") {
      return hander.children();
    } else {
      return hander.label;
    }
  }
  
  const tryEvent = (cell, event) => {
    if(typeof cell.onClick == "function") {
      return cell.onClick(cell, event);
    }
  }
  
  return (
    <TableContainer style={containerStyle}>
      {renderHeader(dataTable.header)}
      {(dataTable.rows || []).length ? renderRows(dataTable.rows) : <NotFound context={context}/>}
    </TableContainer>
  );
};

export default ListGrid;
