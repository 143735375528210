import XLSX from 'xlsx'

const XLSXProvider = {}

//TODO FIX
XLSXProvider.downloadExcel = (fields, name, fileName) => {

    let ws = XLSX.utils.json_to_sheet([getColumns(fields)])
    ws['!cols'] = fields.map(x => ({ wch: 30 }))
    ws.cellStyles = true;

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
}

XLSXProvider.convertWorkSheetToJson = (file) => new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.onload = function (e) {
        let workbook = XLSX.read(e.target.result, { type: 'binary' });
        let data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]],
            { raw: true })
        resolve(data)
    }

    reader.readAsBinaryString(file);
})

const getColumns = (fields) => {
    return fields.reduce((labels, item) => {
        labels[item.label] = null;
        return labels
    }, {})
}

export default XLSXProvider