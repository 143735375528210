import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const SplitButton = ({ option, options, ...props }) => {
  //case need start with other option different to first
  option = option.label && option.value ? option : options[0];
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(option);

  const handleMenuItemClick = (event, option) => {
    setSelectedOption(option);
    props.onChange(option.value);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          size="small"
          variant="contained"
          color="primary"
          style={{ backgroundColor: props.backgroundColor }}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            style={{
              fontSize: props.fontSize,
              backgroundColor: props.backgroundColor,
              borderColor: props.borderColor
            }}
          >
            {selectedOption.label}
          </Button>
          <Button
            color="primary"
            style={{ backgroundColor: props.backgroundColor }}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 1000
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                zIndex: 100
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map(option => (
                      <MenuItem
                        key={option.value}
                        selected={option.value === selectedOption.value}
                        onClick={event => handleMenuItemClick(event, option)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

SplitButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  option: PropTypes.object,
};

SplitButton.defaultProps = {
  value: '',
  fontSize: '0.75em',
  backgroundColor: '#091c3a',
  borderColor: '#1d304e',
  onChange: () => {},
  options: [],
  option: {
    label: null,
    value: null
  }
};

export default SplitButton;
