import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Field from 'components/atoms/Field';

import LoginService from 'services/Login';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emailIsValid, validateEmail] = useState(null);
  const setForgotSuccess = props.setForgotSuccess;

  const submitForgot = async (e) => {
    e.preventDefault();
    setLoading(true);
    validateEmail(null);
    if (!email || !email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      validateEmail(false);
    } else {
      try {
        await LoginService.forgotPassword(email);
        setForgotSuccess(true);
        props.onClose();
      } catch(e) {
        setError(e);
        setTimeout(() => setError(false), 6000);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog {...props} onClose={!loading ? props.onClose : null}>
      <Snackbar open={error} autoHideDuration={500}>
        <Alert severity="error">
          {error}
        </Alert>
      </Snackbar>
      <DialogTitle>
        Esqueci minha senha
      </DialogTitle>
      <form onSubmit={submitForgot}>
        <DialogContent>
          <Field
            label="Digite seu e-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
            variant="outlined"
            type="email"
            fullWidth
            disabled={loading}
            error={{
              message: emailIsValid === false
            }}
            helperText={emailIsValid === false && "O e-mail informado não é válido"}
          />
        </DialogContent>
        <DialogActions>
          {
            loading && (
              <CircularProgress size={16} style={{ marginRight: 16 }} />
              )
            }
          <Button
            variant="contained"
            onClick={props.onClose}
            disabled={loading}
            >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
            >
            Enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ForgotPasswordForm;