import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Delete } from '@material-ui/icons'
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import moment from 'moment';
import SidebarTemplate from "components/templates/Sidebar";
import ListGrid from "components/organisms/ListGrid";
import CustomizedMenus from 'components/organisms/ListGrid/CustomizedMenus';
import PlanService from "services/plan.service";
import PlanForm from "./form";
import { ContainerPage, TopContainerPage, ContentPage, FooterPage, TitlePage, FloatButton } from "../../atoms/StyledsBase/styledIndex";


import "./style.css";

function PlanosPage() {

  const [plans, setPlans] = useState([])
  const [plan, setPlan] = useState({})
  const [form, setForm] = useState(false)

  const weekdays = {
    'sunday': 'Dom', 'monday': 'Seg', 'tuesday': 'Ter',
    'wednesday': 'Qua', 'thursday': 'Qui', 'friday': 'Sex', 'saturday': 'Sab'
  }

  useEffect(() => {
    list()
    console.info('set plans trigger')
  }, []);

  const list = async () => {

    let plans = await PlanService.findAll()

    setPlans(plans.map(plan => {
      return Object.assign({}, plan, {
        weekdays: plan.weekdays.map(weekDay => weekdays[weekDay]).join(','),
        vehicleTypes: plan.vehicleTypes.join(','),
        from: moment(new Date(plan.from)).utc().format('HH:mm'), to: moment(new Date(plan.to)).utc().format('HH:mm')
      })
    }))
  }

  const openForm = (plan) => {
    setPlan(plan || { name: "", weekdays: Object.keys(weekdays) })
    setForm(true)
  }

  const remove = async (planId) => {
    await PlanService.remove(planId)
    list()
  }

  const getDataTable = () => {

    return {
      header: [
        { flex: 2, label: "Name do Plano" },
        { flex: 2, label: "Dias da semana" }, { flex: 3, label: "Horário de uso" },
        { flex: 2, label: "Categorias" },
        { flexDirection: 'column', label: "Editar" }, { flexDirection: 'column', label: "Ações" }
      ],
      rows: getRows(plans)
    }

  }

  const getRows = (plans) => {
    return (plans || []).reduce((rows, plan) => {
      rows.push([
        { flex: 2, label: plan.name },
        { flex: 2, label: plan.weekdays },
        { flex: 3, label: `${plan.from}h - ${plan.to}h` },
        { flex: 2, label: plan.vehicleTypes },
        { flexDirection: 'column', label: "", onClick: "", children: () => renderEditButton(plan) },
        { flexDirection: 'column', children: () => (<CustomizedMenus id={plan.id} items={renderMenuActions(plan)} />) }
      ])
      return rows
    }, [])
  }

  const renderEditButton = (plan) => (<EditIcon style={{ 'color': '#909090' }} onClick={() => { openForm(plan) }} />)

  const renderMenuActions = (row) => ([
    { label: "Excluir", message: `o plano ${row.name}`, confirm: true, element: () => (<Delete />), onClick: (row) => remove(row) }
  ])

  return (
    <SidebarTemplate>
        <ContainerPage>
          {form ? <PlanForm plan={plan} setForm={setForm} refresh={list}></PlanForm> :
          <>
            <FloatButton>
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => openForm()}
              >
                <AddIcon />
              </Fab>
            </FloatButton>
            <TopContainerPage>
              <TitlePage>Planos</TitlePage>
            </TopContainerPage>
            <ContentPage>
              <ListGrid dataTable={getDataTable()} />
            </ContentPage>
          </>
          }
        </ContainerPage>
    </SidebarTemplate>
  )

}

export default PlanosPage;
