import React from 'react';
import PropTypes from 'prop-types';

import './styles.sass';

const WidgetBase = ({ children, title, ...props }) => {

  return (
    <div className="widget-base widget-container--right">
      <div className="widget-base__header">
        <div className="widget-base__header__title">
          {title}
        </div>
        <div className="widget-base__header__aux">
          {props.headerAux}
        </div>
      </div>
      <div className="widget-base__content">
        {children}
      </div>
    </div>
  );
}

WidgetBase.propTypes = {
  title: PropTypes.string.isRequired
}

WidgetBase.defaultProps = {
  title: ""
};

export default WidgetBase;
