import React, { useState, useEffect } from 'react';
import Routes from 'routes';
import { AuthContext } from 'context';
import { getStorage, setStorage } from 'store';
import { events } from 'services/api';
import Loading from 'components/atoms/Load';
import 'toastr/toastr.scss';

function App() {
  const [auth, setAuth] = useState(getStorage());

  useEffect(() => {
    function handle(value) {
      setAuthData(value);
    }

    events.on('onRedirect', handle);
    return () => {
      events.removeListener('onRedirect', handle);
    };
  }, []);

  const setAuthData = data => {
    if (setStorage(data)) {
      setAuth(data);
      return;
    }
    setAuth(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth: setAuthData
      }}
    >
      <Loading />
      <Routes />
    </AuthContext.Provider>
  );
}

export default App;
