/* eslint-disable react/prop-types */
import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Slide
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DialogConfirmation = ({
	open = false,
	title = "Excluir",
	message = "Registro de id 34234",
	closeDialog = () => {},
	action = () => {}
}) => {

	const confirm = () => {
		action();
		closeDialog()
	}

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">Deseja {title} ?</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
				</DialogContentText>
				<DialogContentText id="alert-dialog-slide-description">
					<span>Você está prestes a <b>{title}</b> <b>{message}</b></span>.
				</DialogContentText>
			</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						autoFocus
						onClick={() => closeDialog()}
						className="cancel-btn"
						style={{ backgroundColor: "#fff", color: "#000" }}
					>
						<span className="Cancelar">Cancelar</span>
					</Button>
					<Button
						onClick={() => confirm()}
						autoFocus
						className="continue-btn"
						style={{ backgroundColor: "#308bcb", color: "#fff" }}
					>
						<span className="Continuar">Continuar</span>
					</Button>
			</DialogActions>
		</Dialog>
	)
};

export default DialogConfirmation;
