import styled from "styled-components";

export const TableContainer = styled.div`
	display: flex;
	min-width: 100%;
	min-height: 579px;
	margin-top: 40px;
	justify-content: flex-start;
	flex-direction: column;
	margin-right: 10px;
	box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
	padding: 6px 6px 6px 6px;
	border-radius: 16px;
`;


export const TextHeader = styled.span`
	flex: 1;
	display: flex;
	font-weight: 900;
	color: #656060;
	background-color: #ffffff;
	line-height: 3;
	text-align: initial;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #99a6b7;
	font-family: ProximaNova-Extrabld;
`;

export const TextCell = styled.span`
	font-size: 14px;
	font-weight: ${({ first = 'normal' }) => first};
	color: #5b616f;
	text-align: initial;
	word-break: break-all;
	flex: 1;
	font-family: ProximaNova-Regular;
`;

export const RowHeader = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
    max-height: 48px;
	border-bottom: 1px solid #EDF0F2;
	background-color: #ffffff;
`;

export const Wrapper = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	min-width: 100%;
	&:hover > .RowBodyTable {
		box-shadow: 0 2px 10px rgba(0,0,0,.2);
	}
	&:active > .RowBodyTable {
		background-color: #FFFFFF;
		box-shadow: 0 2px 10px rgba(0,0,0,.2);
	}
`;

export const RowBodyTable = styled.div`
	flex: 1;
	min-width: 100%;
	display: flex;
	flex-direction: row;
    min-height: 64px;
	justify-content: center;
	border-bottom: 1px solid #EDF0F2;
	background-color: #FFFFFF;

	.RowBodyTable first-child > span {
		font-weight: bold !important;
	}
	:nth-child(3) {
		background-color: red;
	}
`;

export const Cell = styled.div`
	display: flex;
	flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
	flex: ${({ flex = 1 }) => flex};
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding-left: 10px;
	padding-right: 10px;
	text-align: inherit;
	flex-wrap: wrap;
	min-width: 70px;
`;
