import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import PlansService from "services/plan.service";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { useAuth } from "context";
import useForm from "react-hook-form";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core";
import ContributorService from 'services/contributor.service'
import { formatBRL } from "helpers/money";
import "./style.css";
import DepartmentService from "services/department.service";
import useStyles from "../../../base/stylesInputs";
import FormGeneric from "components/organisms/FormGeneric";
import ContributorsService from 'services/contributor.service';

let departamentsGlob = [];
let plansGlob = [];

const FormContributorsPage = props => {
  const [plans, setPlans] = useState([]);
  const [departaments, setDepartaments] = useState([]);
  const [plan, setPlan] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [isEdition, setIsEdition] = useState(false);
  const [employeeEdition, setEmployeeEdition] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles(props);
  const { setAuth } = useAuth();
  let history = useHistory();

  useEffect(() => {
    if (props.employeeIdEdit) {
      findTheEmployee(props.employeeIdEdit);
    }
    fetchPlans();
    fetchDepartaments();
  }, []);

  const findTheEmployee = async (id) => {
    try {
      const { data } = await ContributorService.find(id);
      setIsEdition(true);
      setDepartmentId(data.departmentId)
      setPlan(data.limitId);
      data.cpf = formatCPF(data.cpf.toString());
      setEmployeeEdition(data);
    } catch (e) {
      console.error("Problem", e)
    }
  }

  const formatCPF = (cpf) => {
    let b = cpf.split('');
    let c = b.slice(0, 3);
    let d = b.slice(3, 6);
    let e = b.slice(6, 9);
    let f = b.slice(9, 11)
    let cpffinal = `${c.join('')}.${d.join('')}.${e.join('')} - ${f.join('')}`;
    return cpffinal
  }

  async function fetchPlans() {
    try {
      const receivedPlans = await PlansService.findAll();
      plansGlob = receivedPlans;
      setPlans(receivedPlans);
    } catch (e) {
      console.error("Problem", e);
    }
  }

  const isReady = (glob) => {
    if (isEdition) {
      return (!glob.length || !Object.keys((employeeEdition || {})).length && isEdition)
    }
    return false
  }

  const renderAutocompleteDepar = () => {
    if (isReady(departamentsGlob)) {
      return false;
    }
    let departEdit = getDepartamentEdition();

    return (
      <Autocomplete
        id="combo-box-demo"
        defaultValue={departEdit}
        options={departaments || []}
        getOptionLabel={option => option.name}
        onChange={selectDepartament}
        style={{ width: '100%', height: 30 }}
        renderInput={params => (
          <TextField
            {...params}
            id="outlined-basic"
            fullWidth
            label="Departamento*"
            variant="outlined"
            size="small"
            className={classes.selectStyle}
            error={errors.departmentId}
            name="departmentId"
            inputRef={register}
          />
        )}
      />
    )
  }

  const renderAutocompletePlan = () => {
    if (isReady(plansGlob)) {
      return false;
    }
    let departEdit = getPlanEdition();

    return (
      <Autocomplete
        id="combo-box-demo"
        defaultValue={departEdit}
        options={plans || []}
        getOptionLabel={option => option.name}
        onChange={selectPlan}
        style={{ width: '100%', height: 30 }}
        renderInput={params => (
          <TextField
            {...params}
            id="outlined-basic"
            fullWidth
            label="Plano de uso*"
            variant="outlined"
            size="small"
            helperText={(errors.limitId || {}).message}
            className={classes.selectStyle}
            error={errors.limitId}
            name="limitId"
            inputRef={register({
              required: {
                value: true,
                message: "Preenchimento obrigatório do plano de uso"
              }
            })}
          />
        )}
      />
    )
  }

  const getDepartamentEdition = () => {
    let depEdtion = [];
    if (isEdition && employeeEdition && departamentsGlob.length) {
      depEdtion = departamentsGlob.find(dep => dep.id === employeeEdition.departmentId)
    }
    return depEdtion;
  }

  const getPlanEdition = () => {
    let planEdtion = [];
    if (isEdition && employeeEdition && plansGlob.length) {
      planEdtion = plansGlob.find(plan => plan.id === employeeEdition.limitId)
    }
    return planEdtion;
  }

  const selectDepartament = (event, value) => {
    if ((value || {}).id) {
      setDepartmentId(value.id)
    }
    return false
  }

  const selectPlan = (event, value) => {
    if ((value || {}).id) {
      setPlan(value.id)
    }
    return false
  }

  async function fetchDepartaments() {
    const receivedDepartaments = await DepartmentService.findAll({})
    departamentsGlob = receivedDepartaments.data;
    setDepartaments(receivedDepartaments.data);
  }

  const handleChange = event => {
    console.log(event.target.value);
    setPlan(event.target.value);
  };

  async function doSubmitEmployee(data) {
    try {
      let employee = { ...data, id: employeeEdition.id, departmentId: (departmentId || null), limitId: plan };
      employee.monthlyLimit = parseFloat(Number((employee.monthlyLimit || 0)));
      if (isEdition) {
        delete employee.cpf;
        await ContributorsService.edit(employee);
      } else {
        await ContributorsService.save(employee);
      }
      props.onRefresh();
      props.setToogleScreen(false);
    } catch (err) {

    }
    props.onRefresh();
    props.setToogleScreen(false);
  }

  const onChange = (field, event) => {
    let employeeEditionAux = employeeEdition;
    if (isEdition) {
      employeeEditionAux[field] = event.target.value;
      setEmployeeEdition({ ...employeeEditionAux });
    }
  };

  if (Object.keys(employeeEdition).length < 3 && isEdition) {
    return false;
  }

  return (
    <>
      <FormGeneric
        setToogleScreen={props.setToogleScreen}
        isEdition={isEdition}
        onSubmit={handleSubmit(doSubmitEmployee)}
        context="usuário"
      >
      <Grid container spacing={2} style={{ 'margin': '0px' }}>
              <Grid item xs={8}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label="Nome completo*"
                  variant="outlined"
                  size="small"
                  value={employeeEdition.name}
                  defaultValue={employeeEdition.name}
                  className={classes.selectStyle}
                  error={errors.name}
                  name="name"
                  onChange={(e) => onChange('name', e)}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Preenchimento obrigatório do Nome completo"
                    }
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="CPF*"
                  variant="outlined"
                  size="small"
                  disabled={isEdition ? true : false}
                  onChange={(e) => onChange('cpf', e)}
                  value={employeeEdition.cpf}
                  className={classes.selectStyle}
                  error={errors.cpf}
                  name="cpf"
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Preenchimento obrigatório do CPF"
                    }
                  })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ 'margin': '0px' }}>
              <Grid item xs={8}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label="E-mail*"
                  variant="outlined"
                  size="small"
                  onChange={(e) => onChange('email', e)}
                  value={employeeEdition.email}
                  className={classes.selectStyle}
                  error={errors.email}
                  name="email"
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Preenchimento obrigatório do e-mail"
                    }
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Telefone"
                  variant="outlined"
                  size="small"
                  onChange={(e) => onChange('phone', e)}
                  value={employeeEdition.phone}
                  className={classes.selectStyle}
                  error={errors.telefone}
                  name="phone"
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ 'margin': '0px' }}>
              <Grid item xs={8}>
                {renderAutocompleteDepar()}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label="Limite mensal"
                  variant="outlined"
                  size="small"
                  type="number"
                  name="monthlyLimit"
                  inputRef={register}
                  inputProps={{ min: "0", step: "100" }}
                  onChange={(e) => onChange('monthlyLimit', e)}
                  value={employeeEdition.monthlyLimit}
                  helperText={(errors.monthlyLimit || {}).message}
                  error={errors.monthlyLimit}
                  className={classes.selectStyle}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ 'margin': '0px' }}>

          {isEdition && (
            <>
              {/* <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      label="Limite restante"
                      variant="outlined"
                      size="small"
                      value={employeeEdition.limitId}
                      disabled
                      style={{ background: '#e7ebee', borderRadius: "8px" }}
                      className={classes.selectStyle}
                    />
                  </Grid> */}
              <Grid item xs={4}>
                <TextField id="outlined-basic"
                  fullWidth
                  disabled={isEdition ? true : false}
                  variant="outlined"
                  label="Status"
                  size="small"
                  value={employeeEdition.status || ''}
                  name="status"
                  style={{ background: `${isEdition ? '#e7ebee' : ''}`, borderRadius: "8px" }}
                  className={classes.selectStyle}
                />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            {renderAutocompletePlan()}
          </Grid>
        </Grid>
      </FormGeneric>
    </>
  );
};

export default FormContributorsPage;
