import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreVert, Delete } from '@material-ui/icons';
import AlertDialogSlide from './AlertDialogSlide';

export default function SimpleMenu({ items, id }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [confirmAction, setConfirmAction] = React.useState(false);
  const [action, setAction] = React.useState("");
  const [message, setMessage] = React.useState("")

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Dialog = ({ onClick, confirm, label, message }) => {
    handleClose();
    if (confirm) {
      setAction(label)
      setConfirmAction(getAction(onClick))
      setMessage(message)
      setOpenDialog(true);
    } else {
      onClick(id);
    }
  }

  function getAction(onClick) {
    return function () {
      return onClick.bind(null, id)
    }
  }

  return (
    <>
      <AlertDialogSlide action={action} message={message} openDialog={openDialog} setOpenDialog={setOpenDialog} confirmAction={confirmAction} />
      <MoreVert
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick} />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            minWidth: 250,
          },
        }}
      >
        {items.map((Item, index) => (
          <MenuItem key={index} disabled={Item.show} onClick={() => Dialog(Item)}>{Item.element()}<span style={{fontFamily: "ProximaNova-Regular"}} className='Delete-Icon'>{Item.label}</span></MenuItem>
        ))}
      </Menu>
    </>
  );
}
