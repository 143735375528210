import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';

import { ReactComponent as MagnifyIcon } from 'components/base/svg/icons/magnify.svg'

import { useContributors } from 'context';

import './styles.sass';

const PageSearch = props => {

  const { contributors, setContributors } = useContributors();

  const formSearch = useRef();
  const formSubmitHandler = (data) => {
    setContributors({
      search: {
        config: {
          page: contributors.search.config.page,
          sort: contributors.search.config.sort,
          filter: {
            all: data.filter
          }
        }
      },
      data: contributors.data
    })
  }

  const pressKeyEnterHandler = (e) => {
    props.onChange(e)
    if (e.keyCode === 13) {
      formSearch.current.submit();
    }
  }
  const { register, handleSubmit } = useForm();

  return (
    <div className="page-search">
      <form
        ref={formSearch}
        onSubmit={handleSubmit(formSubmitHandler)}
        className="page-search__form"
      >
        <label htmlFor="page-search__form__input-container__elem">
          <MagnifyIcon
            className="page-search__form__icon"
          />
        </label>
        <div className="page-search__form__input-container">
          <input
            name="filter"
            id="page-search__form__input-container__elem"
            className="page-search__form__input-container__elem"
            type="text"
            placeholder={props.placeholder}
            onChange={pressKeyEnterHandler}
            ref={register}
            // onChange={handlerChange}
            // value={props.value}
          />
          <span className="page-search__form__input-container__line"></span>
        </div>
      </form>
    </div>
  );
}

PageSearch.propTypes = {
  destiny: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

PageSearch.defaultProps = {
  destiny: "",
  placeholder: "",
  value: ""
};

export default PageSearch;
