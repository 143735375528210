import React, { useState, useEffect } from 'react';

import {
    Grid,
    Typography,
    IconButton,
    Button
} from '@material-ui/core';
import {
    ArrowBack,
    CloudDownload
} from '@material-ui/icons';
import moment from 'moment';
import ptBr from 'moment/locale/pt-br';

import ListGrid from "components/organisms/ListGrid";
import { TitlePage } from "components/atoms/StyledsBase/styledIndex";
import FinanceService from "services/finance.service";

moment.locale('pt-br', ptBr);

const formatMoney = (value=0) => value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
});

const Debits = ({ selectInvoice, invoice }) => {
    const [debits, setDebits] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const exec = async () => {
            setLoading(true);
            try {
                const data = await FinanceService.invoice(invoice.id);
                setDebits(data);
                console.log(data);
            } catch(e) {
                console.log(e);
            }
            setLoading(false);
        }
        exec();
    }, [invoice]);

    const getDataTable = (key) => {
        const tables = {
            fines: {
                header: [
                    { flex: 1, label: "Placa" },
                    { flex: 1, label: "Modelo" },
                    { flex: 2, label: "Condutor" },
                    { flex: 1, label: "Dpto." },
                    { flex: 1, label: "Infração" },
                    { flex: 1, label: "Multa" },
                    { flex: 1, label: "Taxa" },
                    { flex: 1, label: "Valor Total" },
                    { flex: 2, label: "" },
                ],
                rows: (debits[key] || []).reduce((rows, data) => {
                    rows.push([
                        // { flex: 1, label: moment(new Date(data.startDate)).format('DD/MM/YYYY') },
                        { flex: 1, label: data.plate },
                        { flex: 1, label: data.model },
                        { flex: 2, label: data.employee },
                        { flex: 1, label: data.department },
                        { flex: 1, label: data.infraction },
                        { flex: 1, label: formatMoney(data.value) },
                        { flex: 1, label: formatMoney(data.taxValue) },
                        { flex: 1, label: formatMoney(data.total) },
                        { flex: 2, label: data.file && <Button variant="contained" color="primary" onClick={() => FinanceService.downloadFineFile(data.file)}>DOWNLOAD</Button> }
                    ])
                    return rows
                }, [])
            },
            trips: {
                header: [
                    { flex: 2, label: "Nome" },
                    { flex: 1, label: "Modelo" },
                    { flex: 1, label: "Placa" },
                    { flex: 1, label: "Dpto." },
                    { flex: 1, label: "CC" },
                    { flex: 1, label: "Início" },
                    { flex: 1, label: "Fim" },
                    { flex: 1, label: "Motivo" },
                    { flex: 1, label: "Valor" },
                ],
                rows: (debits[key] || []).reduce((rows, data) => {
                    rows.push([
                        { flex: 2, label: data.employee },
                        { flex: 1, label: data.model },
                        { flex: 1, label: data.plate },
                        { flex: 1, label: data.department },
                        { flex: 1, label: data.costCenter },
                        { flex: 1, label: moment(new Date(data.start)).format('DD/MM/YYYY HH:mm') },
                        { flex: 1, label: moment(new Date(data.end)).format('DD/MM/YYYY HH:mm') },
                        { flex: 1, label: data.reason },
                        { flex: 1, label: formatMoney(data.value) },
                    ])
                    return rows
                }, [])
            },
            revokedTrips: {
                header: [
                    { flex: 2, label: "Nome" },
                    { flex: 1, label: "Modelo" },
                    { flex: 1, label: "Placa" },
                    { flex: 1, label: "Dpto." },
                    { flex: 1, label: "CC" },
                    { flex: 1, label: "Início" },
                    { flex: 1, label: "Fim" },
                    { flex: 1, label: "Km" },
                    { flex: 1, label: "Horas" },
                    { flex: 1, label: "Motivo" },
                    { flex: 1, label: "Valor" },
                ],
                rows: (debits[key] || []).reduce((rows, data) => {
                    rows.push([
                        { flex: 2, label: data.employee },
                        { flex: 1, label: data.model },
                        { flex: 1, label: data.plate },
                        { flex: 1, label: data.department },
                        { flex: 1, label: data.costCenter },
                        { flex: 1, label: moment(new Date(data.start)).format('DD/MM/YYYY HH:mm') },
                        { flex: 1, label: moment(new Date(data.end)).format('DD/MM/YYYY HH:mm') },
                        { flex: 1, label: data.reason },
                        { flex: 1, label: formatMoney(data.value) },
                    ])
                    return rows
                }, [])
            },
            cancelledTrips: {
                header: [
                    { flex: 2, label: "Nome" },
                    { flex: 1, label: "Modelo" },
                    { flex: 1, label: "Placa" },
                    { flex: 1, label: "Dpto." },
                    { flex: 1, label: "CC" },
                    { flex: 1, label: "Início" },
                    { flex: 1, label: "Fim" },
                    { flex: 1, label: "Km" },
                    { flex: 1, label: "Horas" },
                    { flex: 1, label: "Motivo" },
                    { flex: 1, label: "Valor" },
                ],
                rows: (debits[key] || []).reduce((rows, data) => {
                    rows.push([
                        { flex: 2, label: data.employee },
                        { flex: 1, label: data.model },
                        { flex: 1, label: data.plate },
                        { flex: 1, label: data.department },
                        { flex: 1, label: data.costCenter },
                        { flex: 1, label: moment(new Date(data.start)).format('DD/MM/YYYY HH:mm') },
                        { flex: 1, label: moment(new Date(data.end)).format('DD/MM/YYYY HH:mm') },
                        { flex: 1, label: data.reason },
                        { flex: 1, label: formatMoney(data.value) },
                    ])
                    return rows
                }, [])
            },
            refuelRefund: {
                header: [
                    { flex: 1, label: "Data" },
                    { flex: 2, label: "Nome" },
                    { flex: 1, label: "Dpto." },
                    { flex: 1, label: "CC" },
                    { flex: 2, label: "Descrição" },
                    { flex: 1, label: "Valor" },
                ],
                rows: (debits[key] || []).reduce((rows, data) => {
                    rows.push([
                        { flex: 1, label: moment(new Date(data.date)).format('DD/MM/YYYY') },
                        { flex: 2, label: data.employee },
                        { flex: 1, label: data.department },
                        { flex: 1, label: data.costCenter },
                        { flex: 2, label: data.description },
                        { flex: 1, label: formatMoney(data.value) },
                    ])
                    return rows
                }, [])
            },
            residualTolls: {
                header: [
                    { flex: 1, label: "Data" },
                    { flex: 2, label: "Condutor" },
                    { flex: 1, label: "Dpto." },
                    { flex: 1, label: "CC" },
                    { flex: 2, label: "Descrição" },
                    { flex: 1, label: "Valor" },
                ],
                rows: (debits[key] || []).reduce((rows, data) => {
                    rows.push([
                        { flex: 1, label: moment(new Date(data.date)).format('DD/MM/YYYY HH:mm') },
                        { flex: 2, label: data.employee },
                        { flex: 1, label: data.department },
                        { flex: 1, label: data.costCenter },
                        { flex: 2, label: data.description },
                        { flex: 1, label: formatMoney(data.value) },
                    ])
                    return rows
                }, [])
            },
            additionalCharges: {
                header: [
                    { flex: 1, label: "Tipo" },
                    { flex: 1, label: "Data" },
                    { flex: 2, label: "Nome" },
                    { flex: 1, label: "Dpto." },
                    { flex: 1, label: "CC" },
                    { flex: 1, label: "Modelo" },
                    { flex: 1, label: "Placa" },
                    { flex: 1, label: "Valor" },
                    { flex: 2, label: "Observações" },
                ],
                rows: (debits[key] || []).reduce((rows, data) => {
                    rows.push([
                        { flex: 1, label: data.type },
                        { flex: 1, label: moment(new Date(data.date)).format('DD/MM/YYYY HH:mm') },
                        { flex: 2, label: data.employee },
                        { flex: 1, label: data.department },
                        { flex: 1, label: data.costCenter },
                        { flex: 1, label: data.model },
                        { flex: 1, label: data.plate },
                        { flex: 1, label: formatMoney(data.value) },
                        { flex: 2, label: data.description },
                    ])
                    return rows
                }, [])
            },
        }
        return tables[key];
    };

    return <>
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item xs className="title">
                <TitlePage>
                    <IconButton onClick={() => selectInvoice(null)} style={{ marginRight: 16, marginTop: -2 }}>
                        <ArrowBack />
                    </IconButton>
                    {
                        loading ? 'Carregando débitos' : debits && <>
                            Débitos - {moment(debits.dueDate).format('MMMM/YYYY')}
                        </>
                    }
                </TitlePage>
            </Grid>
        </Grid>
        {
            debits && (
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: 16
                    }}
                >
                    {
                        debits.trips && (
                            <Grid item xs={12}>
                                <Typography>
                                    Viagens
                                </Typography>
                                <ListGrid textCellStyle={{ wordBreak: 'break-word' }} containerStyle={{ marginTop: 8, minHeight: 'auto' }} dataTable={getDataTable('trips')} />
                            </Grid>
                        )
                    }
                    {
                        debits.cancelledTrips && (
                            <Grid item xs={12}>
                                <Typography>
                                    Viagens Canceladas
                                </Typography>
                                <ListGrid textCellStyle={{ wordBreak: 'break-word' }} containerStyle={{ marginTop: 8, minHeight: 'auto' }} dataTable={getDataTable('cancelledTrips')} />
                            </Grid>
                        )
                    }
                    {
                        debits.cancelledTrips && (
                            <Grid item xs={12}>
                                <Typography>
                                    Reservas Canceladas
                                </Typography>
                                <ListGrid textCellStyle={{ wordBreak: 'break-word' }} containerStyle={{ marginTop: 8, minHeight: 'auto' }} dataTable={getDataTable('revokedTrips')} />
                            </Grid>
                        )
                    }
                    {
                        debits.fines && (
                            <Grid item xs={12}>
                                <Typography>
                                    Multas
                                </Typography>
                                <ListGrid textCellStyle={{ wordBreak: 'break-word' }} containerStyle={{ marginTop: 8, minHeight: 'auto' }} dataTable={getDataTable('fines')} />
                            </Grid>
                        )
                    }
                    {
                        debits.residualTolls && (
                            <Grid item xs={12}>
                                <Typography>
                                    Passagens
                                </Typography>
                                <ListGrid textCellStyle={{ wordBreak: 'break-word' }} containerStyle={{ marginTop: 8, minHeight: 'auto' }} dataTable={getDataTable('residualTolls')} />
                            </Grid>
                        )
                    }
                    {
                        debits.refuelRefund && (
                            <Grid item xs={12}>
                                <Typography>
                                    Reebolso de combustível
                                </Typography>
                                <ListGrid textCellStyle={{ wordBreak: 'break-word' }} containerStyle={{ marginTop: 8, minHeight: 'auto' }} dataTable={getDataTable('refuelRefund')} />
                            </Grid>
                        )
                    }
                    {
                        debits.additionalCharges && (
                            <Grid item xs={12}>
                                <Typography>
                                    Cobranças Adicionais
                                </Typography>
                                <ListGrid textCellStyle={{ wordBreak: 'break-word' }} containerStyle={{ marginTop: 8, minHeight: 'auto' }} dataTable={getDataTable('additionalCharges')} />
                            </Grid>
                        )
                    }
                </Grid>    
            )
        }
    </>
}

export default Debits;