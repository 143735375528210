import { get, post, remove } from "services/api";

const CostCenterService = {}

//getCostCenter
CostCenterService.findAll = async () => {
    let response = await get({ type: "user", service: "cost-center", queryString: [] })
    return response.data
}

//getTripReason
CostCenterService.findTripReasons = async () => {
    let response = await get({ type: "user", service: "trip-reason", queryString: [] })
    return response.data
}

//createCostCenter
CostCenterService.save = async (costCenter) => {

    return await post({ type: 'user', service: 'cost-center', data: costCenter })
}

//createTripReason
CostCenterService.saveTripReason = async (tripReason) => {

    return await post({ type: 'user', service: 'trip-reason', data: tripReason })
}

CostCenterService.remove = async (costCenterId) => {

    let response = await remove({ type: 'user', uri: `cost-center/${costCenterId}` })
    console.info('CostCenterService.remove', response)
}

CostCenterService.removeReason = async (reasonId) => {

    let response = await remove({ type: 'user', uri: `trip-reason/${reasonId}` })
    console.info('CostCenterService.remove', response)
}

export default CostCenterService