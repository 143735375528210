import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useAuth } from 'context';
import ContributorsPage from 'components/pages/Contributors';
import DashboardPage from 'components/pages/Dashboard';
import LoginPage from 'components/pages/Login';
import PlanosPage from 'components/pages/Planos';
import FinancePage from 'components/pages/Finance';
import DepartamentsPage from 'components/pages/Departments';
import CompaniesPage from 'components/pages/Companies';
import Trip from 'components/templates/TripInfo';
import ResetPasswordPage from '../components/pages/Login/reset-password';
import ReportFinances from '../components/pages/ReportTrips';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const canStrategy = {
    '/companies': roles => (roles || []).includes('master')
  };
  let fn = canStrategy[rest.path];
  let can = typeof fn === 'function' ? fn(rest.roles) : true;
  return (
    <Route
      {...rest}
      render={props =>
        rest.isAuthenticated && can ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const RouterComponent = () => {
  const { auth } = useAuth();
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/reset-password/:uuid" component={ResetPasswordPage} />
        <PrivateRoute
          isAuthenticated={auth}
          path="/"
          exact
          component={DashboardPage}
        />
        <PrivateRoute
          isAuthenticated={auth}
          roles={(auth || {}).roles}
          path="/companies"
          exact
          component={CompaniesPage}
        />
        <PrivateRoute
          isAuthenticated={auth}
          exact
          path="/trip"
          component={Trip}
        />

        <PrivateRoute
          isAuthenticated={auth}
          exact
          path="/contributors"
          component={ContributorsPage}
        />

        <PrivateRoute
          isAuthenticated={auth}
          exact
          path="/departments"
          component={DepartamentsPage}
        />

        <PrivateRoute
          isAuthenticated={auth}
          path="/planos"
          component={PlanosPage}
        />

        <PrivateRoute
          isAuthenticated={auth}
          path="/financial"
          component={FinancePage}
        />

        <PrivateRoute
          isAuthenticated={auth}
          path="/report-trips"
          component={ReportFinances}
        />

        <PrivateRoute
          isAuthenticated={auth}
          path="*"
          exact
          component={DashboardPage}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
