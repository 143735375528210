import { get, patch, post, remove } from "services/api";

const PlanService = {}

PlanService.find = async (id) => {
  let { data } = await get({ type: "user", service: `limit/${id}`, queryString: [] })
  return data
}

PlanService.getVehicleTypes = async () => {
  let response = await get({ type: 'fleet', service: 'vehicle/models-per-category', queryString: [] })
  return (response.data || [])
}

PlanService.findAll = async () => {

  let { data } = await get({ type: "user", service: "limit", queryString: [] })
  return (data || [])
}

PlanService.save = async (plan) => {
  let resp = await post({ type: "user", service: "limit", data: [plan] })
  console.info('PlanService.save', resp)
}

PlanService.update = async (plan) => {
  let resp = await patch({ type: "user", service: `limit/${plan.id}`, data: plan })
  console.info('PlanService.update', resp)
}

PlanService.remove = async (planId) => {
  let resp = await remove({ type: 'user', uri: `limit/${planId}` })
  console.info("#PlanService.remove", planId)
}

export default PlanService
