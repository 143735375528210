import React, { useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useForm from "react-hook-form";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContainerBreadCamps, ItemBreadCamps } from "../../organisms/FormGeneric/styledForm";
import moment from 'moment';
import PlanService from "services/plan.service";
import "./style.css";
import FormGeneric from "components/organisms/FormGeneric";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "red",
        color: props => props.color
    },
    textFieldStyle: {
        borderRadius: "16px",
        boxShadow: "-5px 5px 30px 0 rgba(0, 0, 0, 0.08)",
        backgroundColor: "#ffffff"
    },
    peperSize: {
        borderRadius: "14px"
    },
    buttonStyles: {
        width: "120px",
        height: "125%",
        borderRadius: "8px",
        color: "white",
        fontWeight: "bold",
        fontFamily: "ProximaNova",
        boxShadow: "0 3px 6px 0 rgba(22, 170, 231, 0.16)",
        backgroundColor: "#308bcb",
        textTransform: "capitalize"
    },
    cancelButtonStyles: {
        width: "100%",
        textTransform: "capitalize",
        fontFamily: "ProximaNova",
        height: "125%",
        color: "#909090",
        fontWeight: "bold",
        borderRadius: "8px",
        boxShadow: "0 3px 6px 0 rgba(22, 170, 231, 0.16)"
    },
    headingMain: {
        height: "4px",
        backgroundColor: "#e7ebee",
        border: "none",
        width: "94%"
    },
    breadcrumb: {
        display: "inline-flex",
        margin: "0% 0% 0% 4%",
        color: "#909090",
        fontFamily: "ProximaNova",
        fontSize: "inherit",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25",
        letterSpacing: "normal",
        textAlign: "left"
    },
    arrowAlign: {
        margin: "1% 1% 0% 0%"
    },
    arrow: {
        width: "16px",
        height: "16px",
        color: "gray"
    },
    breadcrumbField: {
        display: "flex",
        fontSize: "9px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    newStyle: {
        padding: "19% 0% 0% 0%",
        margin: "-5% 1% 0% 0%",
        width: "100%"
    },
    searchIcon: {
        color: "#e7ebee"
    },
    //vehicleCard
    vehicleCard: {
        display: 'inline-block',
        padding: '16px',
        margin: '10px',
        maxWidth: 301,
        minWidth: 301
    },
    image: { width: 128, height: 128 },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    selectStyle: {
        borderBottom: '6px',
        borderRadius: "8px",
        border: "solid 2px #e7ebee",
        backgroundColor: "#ffffff",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#363636",
        '& label': {
          fontSize: "16px"
        },
        '& label.Mui-focused': {
          color: '#707070',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#707070',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: "8px"
          },
          '&:hover fieldset': {
            border: "solid 2px #16aae7",
            borderRadius: "8px"
          },
          '&.Mui-focused fieldset': {
            border: "solid 2px #16aae7",
            borderRadius: "8px"
          },
        },
      }
}));

function PlanForm(props) {

    let history = useHistory();
    let isEdition = (!!props.plan.id)
    let weekdays = [
        { key: 'sunday', text: 'D', legend: 'Dom', selected: true }, { key: 'monday', text: 'S', legend: 'Seg', selected: true },
        { key: 'tuesday', text: 'T', legend: 'Ter', selected: true }, { key: 'wednesday', text: 'Q', legend: 'Qua', selected: true },
        { key: 'thursday', text: 'Q', legend: 'Qui', selected: true }, { key: 'friday', text: 'S', legend: 'Sex', selected: true },
        { key: 'saturday', text: 'S', legend: 'Sab', selected: true }
    ]

    const classes = useStyles(props);
    const { register, handleSubmit, errors } = useForm();
    const [plan, setPlan] = useState(false)
    const [weekdaysView, setWeekdaysState] = useState(weekdays)
    const [vehicleTypes, setVehicleTypesState] = useState([])

    useEffect(() => {

        if (plan !== false)
            return

        (async function () {

            let currentPlan = props.plan
            if (props.plan.id) {
                currentPlan = await PlanService.find(props.plan.id)
                let timeFields = ['from', 'to']
                timeFields.forEach(field => {
                    if (!currentPlan[field] && currentPlan[field] !== 0) return
                    currentPlan[field] = moment(currentPlan[field]).utc().format('HH:mm')
                })
            }

            setPlan(currentPlan)
            setWeekdaysByPlan(currentPlan)
            setVehicleCategories(currentPlan)
        }())

    }, []);

    const onSave = (data) => {

        let currentPlan = plan

        //temp
        if (!currentPlan.id && !(currentPlan.vehicleTypes || []).length)
            currentPlan.vehicleTypes = vehicleTypes.map(item => item.category)

        checkFieldTime(currentPlan)
        let promise = ((plan.id) ? PlanService.update(currentPlan) : PlanService.save(currentPlan))
        promise.then(() => {
            props.refresh()
            props.setForm(false)
        })
    }

    const onChange = (field, event) => {
        let currentValue = plan
        currentValue[field] = event.target.value
        setPlan({ ...currentValue })
    }

    const onChangeWeekday = (event, weekday) => {

        weekday.selected = !weekday.selected
        setWeekdaysState(
            weekdaysView.map(item => (item.key == weekday.key) ? weekday : item)
        )

        plan.weekdays = weekdaysView
            .filter(item => item.selected).map(item => item.key)
        setPlan(plan)

        event.preventDefault()
    }

    const onChangeCategory = (event, category) => {

        category.selected = event.target.checked

        setVehicleTypesState(
            vehicleTypes.map(item => (item.category == category.category) ? category : item)
        )

        plan.vehicleTypes = vehicleTypes
            .filter(item => item.selected).map(item => item.category)
        setPlan(plan)

        event.preventDefault()
    }

    const checkFieldTime = (currentPlan) => {
        let dateFields = ['from', 'to']
        for (const field of dateFields) {
            if (!currentPlan[field])
                continue
            let [hour, minute] = currentPlan[field].split(':')
            let date = new Date()
            date.setHours(hour, minute)
            currentPlan[field] = date.setHours(date.getHours() - 3)
        }
    }

    const setVehicleCategories = (plan) => {
        PlanService.getVehicleTypes()
            .then(vehicleTypes => {
                setVehicleTypesState((vehicleTypes || []).map(item =>
                    ({ ...item, selected: (plan.id ? plan.vehicleTypes.includes(item.category) : true) })
                ))
            })
    }

    const setWeekdaysByPlan = (plan) => {
        if (!plan.id)
            return
        setWeekdaysState(
            weekdaysView.map(item => (
                { ...item, selected: (plan.weekdays || []).includes(item.key) }
            ))
        )
    }

    if (plan === false && isEdition)
        return false;

    return (
        <>
            <FormGeneric 
                setToogleScreen={props.setForm}
                isEdition={isEdition}
                onSubmit={handleSubmit(onSave)}
                context="planos"
            >
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Nome do Plano*"
                            variant="outlined"
                            size="small"
                            value={plan.name}
                            className={classes.selectStyle}
                            error={errors.name}
                            name="name"
                            onChange={(e) => onChange('name', e)}
                            inputRef={register({
                                required: {
                                    value: true,
                                    message: "Preenchimento obrigatório do Nome do Plano"
                                }
                            })}
                        />
                    </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <p style={{ 'letter-spacing': 0, 'color': '#5B616F', 'opacity': 1 }}>Dias da Semana</p>
                        <div className="weekDays-selector">
                            {(weekdaysView || []).map(weekday => (
                                <div style={{ display: 'inline-block', cursor: 'pointer', 'marginLeft': '10px' }}
                                    onClick={(e) => onChangeWeekday(e, weekday)}>
                                    <input type="checkbox" id={weekday.key} className="weekday" value={weekday.key} checked={weekday.selected} />
                                    <label style={{ 'cursor': 'pointer' }} htmlFor={weekday.key} style={{ 'user-select': 'none' }}>{weekday.text}</label>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ 'marginTop': '20px' }}>
                    <Grid container item xs={12} spacing={4}>
                        <Grid item>Horário de uso: </Grid>
                        <Grid item>
                            <TextField type="time" className={classes.textFieldStyle}
                                value={plan.from} onChange={(e) => onChange('from', e)} />
                        </Grid>
                        <Grid item> às </Grid>
                        <Grid item>
                            <TextField type="time" className={classes.textFieldStyle}
                                value={plan.to} onChange={(e) => onChange('to', e)} />
                        </Grid>
                    </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ 'marginTop': '20px' }}>
                    <Grid item>
                        <span>Categorias</span>
                        <p style={{ 'color': '#99A6B7', 'marginLeft': '10px' }}>
                            <small>Escolha as categorias que o usuário terá acesso:</small></p>
                        <div style={{ 'flexGrow': 1 }}>
                            {(vehicleTypes || []).map(type => (
                                <Paper className={classes.vehicleCard} elevation={3}>
                                    <Grid container spacing={4}>
                                        <Grid item>
                                            <ButtonBase className={classes.image}>
                                                <img className={classes.img} alt="complex" src={type.picture} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs >
                                                    <Typography style={{ 'marginTop': '20px' }} variant="body2" gutterBottom> {type.category}</Typography>
                                                    <Typography variant="body2" color="textSecondary">{type.models.join(',')}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{ cursor: 'pointer' }}>
                                                        <Switch
                                                            checked={type.selected}
                                                            onChange={(e) => onChangeCategory(e, type)}
                                                            value={type.category}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>))
                            }
                        </div>
                    </Grid>
                </Grid>
            </FormGeneric>    
        </>
    );

}

export default PlanForm