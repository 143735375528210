import React from 'react'
import {Grid} from '@material-ui/core';
import logo from 'components/base/svg/icons/confusion.svg'
import './Styles.css'

function Nodatafound({context}) {
    return (
        <Grid conatiner >
            <Grid item xs={12} style={{paddingTop:'5%',textAlign:'center'}} > 
              <span style={{fontFamily: "ProximaNova-Bold"}} className='nodatatext'>Nenhum {context} cadastrado</span>
            </Grid>
            <Grid item xs={12} style={{paddingTop:'5%',textAlign:'center'}}>
                <img src={logo}/>
            </Grid>
            <Grid item xs={12} style={{paddingTop:'5%',paddingBottom:'5%',textAlign:'center'}}>
               <span style={{fontFamily: "ProximaNova-Regular"}} className='nodatatext1'>Ainda não há {context} cadastrados, para começar basta digitar e clicar em <b>Inserir.</b></span>
            </Grid>   
        </Grid>
    )
}

export default Nodatafound;
