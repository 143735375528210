import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";

import { Grid } from "@material-ui/core";
import { TextField, withStyles, Button } from "@material-ui/core";
import { Delete } from '@material-ui/icons'

import ListGrid from "components/organisms/ListGrid";
import CustomizedMenus from 'components/organisms/ListGrid/CustomizedMenus';

import CostCenterService from 'services/cost.center.service'

import './styles.css'

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#99a6b7',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#99a6b7',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#99a6b7',
      },
      '&:hover fieldset': {
        borderColor: '#99a6b7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#16aae7',
      },
    },
  },
})(TextField);

const useStyles = theme => ({
  costInput: {
    width: '256px',
    height: '35px',
    backgroundColor: '#ffffff'
  },
  textFieldInputLabel: {
    opacity: '0.60',
    fontFamily: 'ProximaNova',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#5b616f'
  },
  contentInput: {
    width: '750px',
    height: '35px',
    backgroundColor: '#ffffff',
    textTransform: 'none'
  }
});

function Reason(props) {

  const getNewReason = () => ({ name: '' })

  const { register, handleSubmit, errors } = useForm();
  const [reason, setReasonState] = useState(getNewReason())
  const [reasons, setReasonsSate] = useState([])
  const { classes } = props;

  useEffect(() => {
    list()
  }, [])

  const list = async () => {
    let reasons = await CostCenterService.findTripReasons()
    setReasonsSate(reasons)
    setReasonState(getNewReason())
  }

  const onChange = (field, event) => {
    let currentValue = reason
    currentValue[field] = event.target.value
    console.info('onChange reason form:', event.target.value)
    setReasonState({ ...reason })
  }

  const onSave = async () => {
    await CostCenterService.saveTripReason([reason])
    list()
  }

  const remove = async (reasonId) => {
    await CostCenterService.removeReason(reasonId)
    list()
  }

  const getDataTable = () => ({
    header: [
      { flex: 2, label: "Justificativa" }, { flexDirection: 'column', label: "Ações" }
    ],
    rows: getRows(reasons)
  })

  const getRows = (reasons) => (
    (reasons || []).reduce((rows, item) => {
      rows.push([
        { flex: 2, label: item.name },
        { flexDirection: 'column', children: () => (<CustomizedMenus id={item.id} items={renderMenuActions(item)} />) }
      ])
      return rows
    }, [])
  )

  const renderMenuActions = (row) => ([
    { label: "Excluir", message: `a justificativa da viagem ${row.name}`, confirm: true, element: () => (<Delete />), onClick: (row) => remove(row) }
  ])

  return (
    <React.Fragment>

      <Grid container >
        <Grid item xs={12}>
          <span className='header1'> Justificativa da viagem </span>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '1%' }}>
          <span className='content'>
            Ofereça aos seus colaboradores “Justificativas” pré-definidos no momento da reserva.
                </span>
        </Grid>

        <form onSubmit={handleSubmit(onSave)} style={{ 'display': 'contents' }}>

          <Grid container spacing={3} style={{ 'marginTop': '20px' }}>
            <Grid container item xs={12} spacing={4}>
              <Grid item>
                <CssTextField
                  size="small"
                  name='name'
                  className={classes.contentInput}
                  label="Motivo de Viagem"
                  value={reason.name}
                  error={errors.name}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Preenchimento obrigatório da justificativa"
                    }
                  })}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldInputLabel,
                    }
                  }}
                  type="text"
                  onChange={(e) => onChange('name', e)}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Button type="submit"
                  style={{ backgroundColor: '#308bcb' }}
                  variant="contained">
                  <span style={{ color: 'white' }}>Inserir</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Grid item xs={12} style={{ paddingTop: '2%' }}>
          <ListGrid dataTable={getDataTable()} />
        </Grid>

      </Grid >

    </React.Fragment >
  )

}

export default withStyles(useStyles)(Reason);