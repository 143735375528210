import styled from "styled-components";

export const ContainerPage = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 20px 46px 46px;
`;

export const TopContainerPage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0px;
    margin-bottom: 0px;
`;

export const ContentPage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FooterPage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
`;

export const Item = styled.div`
    align-self: ${({align = 'center'}) => align};
`;

export const FloatButton = styled.div`
    position: fixed;
    right: 4.4%;
    bottom: 10%;
`;

export const TitlePage = styled.span`
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #5b616f;
    padding-top: 20px;
`;