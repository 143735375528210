import XLSXProvider from 'providers/xlsx.provider';
import EventEmmiter from 'providers/event.emmiter'
import { events } from 'services/api';


const ContributorUploadService = {}

//temp
const fields = [
    { name: 'name', label: 'Nome Completo*', required: true, regexValidation: /\w+\s\w+/g },
    { name: 'email', label: 'E-mail*', required: true, regexValidation: /\w+@\w+\.\w+\.?\w{0,}/g },
    { name: 'cpf', label: 'CPF*', required: true },
    { name: 'department', label: 'Departamento', required: false },
    { name: 'phone', label: 'Telefone', required: false },
    { name: 'monthLimit', label: 'Limite mensal', required: false, isNumber: true },
    { name: 'plan', label: 'Plano de uso', required: false }
]

ContributorUploadService.getByFile = async (file) => {
    events.emmit('onLoad', true);
    let data = await XLSXProvider.convertWorkSheetToJson(file)
    console.info('convertWorkSheetToJson:', data)

    if (!data.length) {
        events.emmit('onLoad', false);
        throw { error: 'empty_data', message: 'Planilha vazia' }
    }

    let validateResult = validateJson(data)
    console.info('ValidateJson', validateResult)

    let contributtors = getDistinctUsers(validateResult.valid)
    console.info('Contributtors by upload', contributtors)

    events.emmit('onLoad', false);
    return { valid: contributtors, invalid: validateResult.invalid }
}

ContributorUploadService.download = () => {
    XLSXProvider
        .downloadExcel(fields, 'Colaboradores', 'Planilha_Modelo_Colaboradores.xlsx')
}

const getDistinctUsers = (users) => (
    Array.from(new Set(users.map(x => x.cpf)))
        .map((cpf) => users.find(x => x.cpf == cpf))
)

const mergeUser = (user) => {
    return Object.keys(user).reduce((newItem, key) => {
        let field = fields.find(x => x.label == key)
        newItem[field.name] = field.isNumber ? user[field.label] : (user[field.label]).toString()
        return newItem
    }, {})
}

const validateJson = (contributors) => {
    return contributors.reduce((obj, item) => {
        let requiredFilled = fields.filter(x => x.required).every(field => item[field.label])
        if (!requiredFilled) {
            obj.invalid.push({ ...item, message: 'Todos os campos obrigatorios devem ser preenchidos' })
            return obj
        }
        let isValid = fields.filter(x => x.required && x.regexValidation)
            .every(field => item[field.label].match(field.regexValidation))
        if (!isValid) {
            obj.invalid.push({ ...mergeUser(item), message: 'Informação no formato incorreto' })
            return obj
        }
        obj.valid.push(mergeUser(item))
        return obj
    }, { invalid: [], valid: [] })
}


export default ContributorUploadService