/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import {
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import LoginBaseTemplate from 'components/templates/LoginBase';
import Field from 'components/atoms/Field';
import FieldPassword from 'components/atoms/FieldPassword';

import { ReactComponent as InfoErrorIcon } from 'components/base/svg/icons/info-error.svg';
import ForgotPasswordForm from './ForgotPasswordForm';
import './styles.sass';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginTemplate = props => {

  const [hasError, setHasError] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isForgot, setForgot] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [errors]);

  useEffect(() => {
    setHasError(props.formSubmitError);
  }, [props.formSubmitError]);

  useEffect(() => {
    if (forgotSuccess) {
      setTimeout(() => setForgotSuccess(false), 6000);
    }
  }, [forgotSuccess]);

  return (
    <LoginBaseTemplate>
      {forgotSuccess && (
        <Snackbar open={forgotSuccess} autoHideDuration={500}>
          <Alert severity="success">
            Solicitação de recuperação de senha enviada
          </Alert>
        </Snackbar>
      )}
      <ForgotPasswordForm
        open={isForgot}
        onClose={() => setForgot(false)}
        fullWidth
        maxWidth="sm"
        setForgotSuccess={setForgotSuccess}
      />
      <div className="login-sign-in">
        <form
          className="login-sign-in__form"
          onSubmit={handleSubmit(props.formSubmitHandler)}
        >
          <h1 className="login-sign-in__form__title">Já tem conta?</h1>
          <h2 className="login-sign-in__form__desc">Acesse a <b>Turbi Empresas</b></h2>
          <Field
            className="login-input"
            error={errors.email}
            inputRef={register({
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Email inválido'
              },
              required: {
                value: true,
                message: 'Preenchimento obrigatório do e-mail'
              }
            })}
            label="Digite seu e-mail"
            name="email"
            type="email"
            variant="outlined"
          />
          <FieldPassword
            AriaLabelIconPassword="toggle password visibility"
            className="login-input"
            error={errors.password}
            inputRef={register({
              required: {
                value: true,
                message: 'Preenchimento obrigatório da senha'
              }
            })}
            name="password"
            label="Digite sua senha"
            labelWidth={120}
          />
          {
            hasError && (
              <div className="login-sign-in__aux">
                <>
                  <InfoErrorIcon className="login-sign-in__icon-error" />
                  <div className="login-sign-in__error__container">
                    <b className="login-sign-in__error__label">Dados Incorretos:</b>
                    {
                      errors && Object.keys(errors).map((fieldKey) => {
                        return (
                          <span key={fieldKey}>{errors[fieldKey].message}</span>
                        )
                      })
                    }
                    {
                      props.formSubmitError ? (
                        <>Tente novamente</>
                      ) : ''
                    }
                  </div>
                </>
              </div>
            )
          }
          <input type="submit" className="login-btn" value="Entrar" />
          <div className="login-sign-in__forgot">
            <a href="#" onClick={() => setForgot(true)}>
              Esqueci minha senha
            </a>
          </div>
        </form>
      </div>
    </LoginBaseTemplate>
  );
}

LoginTemplate.propTypes = {
  formSubmitError: PropTypes.bool,
  formSubmitHandler: PropTypes.func
}

LoginTemplate.defaultProps = {
  formSubmitError: false,
  formSubmitHandler: () => {}
};

export default LoginTemplate;
