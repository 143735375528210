import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Chart from 'react-apexcharts';

import { formatBRL } from 'helpers/money';

import SplitButton from 'components/atoms/SplitButton';
import WidgetBase from 'components/molecules/WidgetBase';

import './styles.sass';

const WidgetPerformance = ({ data, filters, filtersChange, ...props }) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [defaultValue, setDefaultValue] = useState('corridas');

  useEffect(() => {
    const seriesLocal = [];
    const labelLocal = [];
    if (data.length > 0) {
      data.forEach(item => {
        labelLocal.push(item.label);
        seriesLocal.push(item.value);
      });
    }
    setLabels(labelLocal);
    setSeries(seriesLocal);
  }, [data]);

  const handlerFiltersChange = value => {
    setDefaultValue(value);
    filtersChange(value);
  };

  let options = {
    axisBorder: {
      show: true,
      offsetX: 0,
      offsetY: 0
    },
    chart: {
      events: {
        mouseMove: function(event, chartContext, config) {
          var tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
          var pointsArray = config.globals.pointsArray;
          var seriesIndex = config.seriesIndex;
          var dataPointIndex =
            config.dataPointIndex === -1 ? 0 : config.dataPointIndex;

          if (seriesIndex !== -1) {
            var position = pointsArray[seriesIndex][dataPointIndex];

            tooltip.style.top = position[1] + 'px';
            tooltip.style.left = position[0] + 'px';
          }
        }
      },
      defaultLocale: 'pt-BR',
      locales: [
        {
          name: 'pt-BR',
          options: {
            months: [
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro'
            ],
            shortMonths: [
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez'
            ],
            days: [
              'Domingo',
              'Segunda',
              'Terça',
              'Quarta',
              'Quinta',
              'Sexta',
              'Sabado'
            ],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
          }
        }
      ],
      toolbar: {
        show: false
      },
      type: 'area',
      zoom: {
        enabled: false
      }
    },
    grid: {
      padding: {
        left: 10,
        right: 0
      }
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: undefined
      }
    ],
    stroke: {
      curve: 'straight'
    },
    series: [
      {
        name: 'Corridas',
        data: series
      }
    ],
    labels: labels,
    xaxis: {
      min: props.chartRange.lte,
      max: props.chartRange.gte,
      type: 'datetime',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 1,
      labels: {
        formatter: function(val, index) {
          if (defaultValue === 'corridas') {
            return `${parseInt(val)}`;
          } else {
            return formatBRL(val);
          }
        }
      }
    },
    legend: {
      horizontalAlign: 'left'
    },
    tooltip: {
      theme: false,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="chart_box">' +
          '<span>' +
          series[seriesIndex][dataPointIndex] +
          '</span>' +
          '</div>'
        );
      }
    }
  };

  return (
    <div className="widget-performance">
      <WidgetBase
        title="Performance"
        headerAux={
          <>
            <SplitButton onChange={handlerFiltersChange} options={filters} />
          </>
        }
      >
        <div className="widget-performance__container">
          <Chart options={options} series={options.series} type="area" />
        </div>
      </WidgetBase>
    </div>
  );
};

WidgetPerformance.propTypes = {
  chartRange: PropTypes.object,
  data: PropTypes.array,
  filters: PropTypes.array,
  filtersChange: PropTypes.func
};

WidgetPerformance.defaultProps = {
  chartRange: {},
  data: [],
  filters: [],
  filtersChange: () => {}
};

export default WidgetPerformance;
