export const formatBRL = (value) => {
  if (!value){
    value = '0';
  }

  return new Intl.NumberFormat('pt-BR',
    {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    }
  ).format(value)

}

export default {
  formatBRL
}