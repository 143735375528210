import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ThemeContainer from 'components/atoms/ThemeContainer';

const Field = props => {

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <ThemeContainer>
      <FormControl variant="outlined">
        <InputLabel
          error={props.error.message ? true : false}
          htmlFor={props.name}
        >
          {props.label}
        </InputLabel>
        <OutlinedInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={props.AriaLabelIconPassword}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {
                  values.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )
                }
              </IconButton>
            </InputAdornment>
          }
          error={props.error.message ? true : false}
          inputRef={props.inputRef}
          label={props.label}
          labelWidth={props.labelWidth}
          name={props.name}
          onChange={handleChange('password')}
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
        />
      </FormControl>
    </ThemeContainer>
  );
}

Field.propTypes = {
  AriaLabelIconPassword: PropTypes.string.isRequired,
  error: PropTypes.object,
  inputRef: PropTypes.func,
  label: PropTypes.string.isRequired,
  labelWidth: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}

Field.defaultProps = {
  AriaLabelIconPassword: "",
  error: {},
  inputRef: () => {},
  label: "",
  labelWidth: 100,
  name: "",
};

export default Field;