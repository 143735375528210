import React from 'react';
import PropTypes from 'prop-types';

import BaseTemplate from 'components/templates/Base';
import { Grid, makeStyles } from '@material-ui/core';
import './styles.sass';
import Layout from './Layout';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.root,
    marginTop: '55px',
    minHeight: '100%',
    overflow: 'visible'
  }
}));

export const WrapperContent = ({ children }) => (
  <Grid style={{ padding: '20px 46px 46px' }}>{children}</Grid>
);

const Sidebar = props => {
  const classes = useStyles();

  return (
    <BaseTemplate>
      <Grid id="container" className="sidebar-template">
        <Grid>
          <Layout />
        </Grid>
        <Grid id="subContainer" item container className={classes.root}>
          {props.children}
        </Grid>
      </Grid>
    </BaseTemplate>
  );
};

Sidebar.propTypes = {
  children: PropTypes.element.isRequired
};

export default Sidebar;
