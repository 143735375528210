import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ContainerBreadCamps, ItemBreadCamps } from "../../../organisms/FormGeneric/styledForm";
import DepartmentService from "services/department.service";
import ContributorsService from "services/contributor.service";
import useStyles from "../../../base/stylesInputs";
import FormGeneric from "components/organisms/FormGeneric";
import "./style.css";


const FormDepartmentPage = (props) => {

  const isEdition = (!!props.departamentEdit)

  const [department, setDepartamentState] = useState(false);
  const [leaderEmployeeList, setLeaderEmployeeList] = useState([]);
  const [leaderEmployee, setLeaderEmployee] = useState();

  const { register, handleSubmit, errors } = useForm();

  let history = useHistory();

  const classes = useStyles({});

  useEffect(() => {
    (async function () {

      let currentDepartment = {}
      if (props.departamentEdit)
        currentDepartment = await find(props.departamentEdit);

      findEmployers(currentDepartment);

    }())
  }, []);

  const findEmployers = async (department = {}) => {

    const { data } = await ContributorsService.findAll({});
    setLeaderEmployeeList(data);

    let leader = {}
    if (department.id) {
      leader = (data || []).find(employee => employee.id === department.leaderEmployeeId)
      setLeaderEmployee((leader || {}))
    }
  }

  const find = async (id) => {
    const { data } = await DepartmentService.find(id);
    setDepartamentState(data)
    return data
  }

  const onChange = (field, event) => {
    let currentDepartment = (department || {});
    currentDepartment[field] = (field == 'monthlyLimit' && event.target.value < 0) ? 0 : event.target.value;
    setDepartamentState({ ...currentDepartment });
  }

  async function doSubmitEmployee(data) {

    data.leaderEmployeeId = leaderEmployee.id;
    await (isEdition ? DepartmentService.edit({ ...data, id: department.id })
      : DepartmentService.save(data))

    props.onRefresh();
    props.setToogleScreen(false);
  }

  const selectLeaderEmployee = (event, value) => {
    if ((value || {}).id) {
      setLeaderEmployee(value)
    }
    return false
  }

  const renderHead = () => (
    <ContainerBreadCamps style={{ 'marginLeft': '0px' }}>
      <ItemBreadCamps onClick={() => props.setToogleScreen(false)}>
        <ArrowBackIcon
          className={classes.arrow}
        />
      </ItemBreadCamps>
      <ItemBreadCamps onClick={() => history.push('/')}>
        Dashboard |
      </ItemBreadCamps>
      <ItemBreadCamps onClick={() => props.setToogleScreen(false)}>
        Departamentos |
      </ItemBreadCamps>
      <ItemBreadCamps>
        {`${isEdition ? 'Alterar Cadastro' : 'Adicionar novo'}`}
      </ItemBreadCamps>
    </ContainerBreadCamps>
  )

  const renderAutocompleteLeaderEmployee = () => {
    if (isEdition && !leaderEmployee)
      return false

    return (
      <Autocomplete
        id="combo-box-demo"
        defaultValue={leaderEmployee}
        options={leaderEmployeeList}
        getOptionLabel={option => option.name}
        onChange={selectLeaderEmployee}
        style={{ width: '100%', height: 30 }}
        renderInput={params => (
          <TextField
            {...params}
            id="outlined-basic"
            fullWidth
            label="nome do responsável*"
            variant="outlined"
            size="small"
            helperText={(errors.leaderEmployeeId || {}).message}
            className={classes.selectStyle}
            error={errors.leaderEmployeeId}
            name="leaderEmployeeId"
            error={errors.name}
            inputRef={register({
              required: {
                value: true,
                message: "Preenchimento obrigatório do nome do responsavel"
              }
            })}
          />
        )}
      />
    )
  }

  const returnInputs = () => (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <TextField
          id="outlined-basic"
          fullWidth
          label="Nome do departmento*"
          variant="outlined"
          size="small"
          value={department.name}
          className={classes.selectStyle}
          error={errors.name}
          name="name"
          onChange={(e) => onChange('name', e)}
          inputRef={register({
            required: {
              value: true,
              message: "Preenchimento obrigatório do Nome completo"
            }
          })}
        />
      </Grid>
      <Grid item xs={4}>
        {renderAutocompleteLeaderEmployee()}
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows="4"
          defaultValue={department.description}
          variant="outlined"
          id="outlined-basic"
          fullWidth
          label="descrição"
          name="description"
          className={classes.selectStyle}
          value={department.description}
          onChange={(e) => onChange('description', e)}
          inputRef={register}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Limite mensal"
          variant="outlined"
          size="small"
          type="number"
          min="0"
          name="monthlyLimit"
          inputProps={{ min: "0", step: "100" }}
          className={classes.selectStyle}
          value={department.monthlyLimit}
          onChange={(e) => onChange('monthlyLimit', e)}
          inputRef={register}
        />
      </Grid>
    </Grid>
  )

  if (department === false && isEdition)
    return false;

  return (
    <>
      <FormGeneric 
        setToogleScreen={props.setToogleScreen}
        isEdition={isEdition}
        onSubmit={handleSubmit(doSubmitEmployee)}
        context="departamento"
      >
        {returnInputs()}
      </FormGeneric>
    </>
  )
};

export default FormDepartmentPage;
