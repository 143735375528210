import React from 'react';
import './styles.sass';

import { ReactComponent as TurbiLogo } from 'components/base/svg/logo/1.svg';

const LoginBaseTemplate = props => {

  return (
    <div className="login-template">
      <div className="login-container">
        <TurbiLogo
          className="login-logo"
        />
        <div className="login-box">
          <div className="login-box__content">
            {props.children}
          </div>
          <div className="login-box__footer">
            <div className="login-box__footer">
              <span>Ainda não tem Turbi Empresas?&nbsp;</span>
              <a className="login-box__footer__link"
                href="https://turbi.com.br/empresas/">CADASTRE AGORA
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginBaseTemplate;
