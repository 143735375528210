import { createContext, useContext } from 'react';

export const AuthContext = createContext();
export const ContributorsContext = createContext();
export const ContributorsSearchContext = createContext();
export const DepartamentContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useContributors() {
  return useContext(ContributorsContext);
}

export function useContributorsSearch() {
  return useContext(ContributorsSearchContext);
}

export default {
  AuthContext,
  ContributorsContext,
  DepartamentContext,
  ContributorsSearchContext
};