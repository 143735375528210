import { get, events, patch } from "services/api";

const FinanceService = {}
const DEFAULT_URI = 'invoice'

FinanceService.findAll = async () => {
    let { data } = await get({ type: "user", service: DEFAULT_URI, queryString: [] })
    return data
}

FinanceService.aprovePaid = async (id) => {
    let { data } = await patch({ type: "user", service: `${DEFAULT_URI}/${id}/paid`})
    return data
}

FinanceService.downloadDebitFile = async (invoiceId) => {
    download(
        `https://turbi-user-dot-turbi-22388.appspot.com/b2b/${DEFAULT_URI}/${invoiceId}/download/debit-note`)
}

FinanceService.downloadFineFile = (file) => {
    download(file)
}

FinanceService.downloadPaymentSlip = async (invoice) => {
    download(invoice.boletoUrl)
}

FinanceService.invoice = async (id) => {
    let { data } = await get({ type: "user", service: `${DEFAULT_URI}/${id}/details`, queryString: [] })
    return data
}

FinanceService.updateBillet = async (payload) => {
  let { data } = await patch({ type: "user", service: `${DEFAULT_URI}/${payload.id}/update-boleto`, data: {dueDate: payload.dueDate} })
  return data
}

const download = (url) => {
    events.emmit('onLoad', true)
    setTimeout(() => {
        let link = document.createElement('a')
        link.href = url
        link.click()
        events.emmit('onLoad', false)
    }, 0)
}

export default FinanceService