import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DashboardTemplate from 'components/templates/Dashboard';
import DashboardService from 'services/Dashboard';
import { useAuth } from 'context';

const Dashboard = props => {
  const { setAuth } = useAuth();
  const [lastTrips, setLastTrips] = useState({});
  const [tripsAverageTicket, setTripsAverageTicket] = useState({});
  const [tripsVehicles, setTripsVehicles] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [tracingChart, setTracingChart] = useState([]);
  const [searchPeriod, setSearchPeriod] = useState({
    gte: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}T00:00:00Z`,
    lte: `${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}T00:00:00Z`
  });

  const [tripsFilter, setTripsFilter] = useState('mes-corrente');
  const [performanceFilter, setPerformanceFilter] = useState('corridas');
  const [tracingChartFilter, setTracingChartFilter] = useState('custos');
  const [performanceChartRange, setPerformanceChartRange] = useState({
    gte: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}T00:00:00Z`,
    lte: `${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}T00:00:00Z`
  });

  const [tripsFilters] = useState([
    {
      label: 'Mês Corrente',
      value: 'mes-corrente'
    },
    {
      label: 'Semana Passada',
      value: 'semana-passada'
    },
    {
      label: 'Mês Passado',
      value: 'mes-passado'
    },
    {
      label: 'Últimos 3 meses',
      value: 'ultimos-3-meses'
    },
    {
      label: 'Hoje',
      value: 'hoje'
    }
  ]);

  const [performanceFilters] = useState([
    {
      label: 'Corridas',
      value: 'corridas'
    },
    {
      label: 'Preços',
      value: 'precos'
    }
  ]);
  const [tracingChartFilters] = useState([
    {
      label: 'Custos',
      value: 'custos'
    },
    {
      label: 'Departamento',
      value: 'departamento'
    }
  ]);

  const [widgetTripsSearch, setWidgetTripsSearch] = useState({
    filter: {
      period: searchPeriod
    },
    page: {
      number: 0
    }
  });

  async function fetchtTripsAverageTicket(search) {
    const res = await DashboardService.getTripsAverageTicket(search);
    if (res.status === 401) {
      setAuth();
    }

    if (res.status === 204) {
      setTripsAverageTicket({});
    }

    if (res.status === 200) {
      setTripsAverageTicket(res.data);
    }
  }

  async function fetchTripsList(search) {
    const res = await DashboardService.getTrips(search);
    if (res.status === 401) {
      setAuth();
    }

    if (res.status === 204) {
      setLastTrips({});
    }

    if (res.status === 200) {
      setLastTrips(res.data);
    }
  }

  async function fetchTripsVehicles(search) {
    const res = await DashboardService.getTripsVehicles(search);
    if (res.status === 401) {
      setAuth();
    }

    if (res.status === 204) {
      setTripsVehicles([]);
    }

    if (res.status === 200) {
      setTripsVehicles(res.data);
    }
  }

  async function fetchPerformance(search) {
    let res = '';

    if (performanceFilter === 'corridas') {
      res = await DashboardService.getPerformanceByTrips(search);
    } else if (performanceFilter === 'precos') {
      res = await DashboardService.getPerformanceByCost(search);
    }

    if (res.status === 401) {
      setAuth();
    }

    if (res.status === 204) {
      setPerformance([]);
    }

    if (res.status === 200) {
      if (res.data.length > 0 && tripsFilter === 'mes-corrente') {
        let a = [
          {
            label: `${moment(res.data[0].label)
              .subtract('2', 'd')
              .format('YYYY-MM-DD')}T00:00:00Z`,
            value: 0
          }
        ];
        res.data = [...a, ...res.data];
      }
      setPerformance(res.data);
    }
  }

  async function fetchTracingChart(search) {
    let res = '';

    if (tracingChartFilter === 'custos') {
      res = await DashboardService.getTracingChartsByCostCenter(search);
    } else if (tracingChartFilter === 'departamento') {
      res = await DashboardService.getTracingChartsByDepartment(search);
    }
    if (res.status === 401) {
      setAuth();
    }

    if (res.status === 204) {
      setTracingChart([]);
    }

    if (res.status === 200) {
      setTracingChart(res.data);

      if (res.data.length > 0) {
        setPerformanceChartRange({
          gte: res.data[0],
          lte: res.data[res.data.length]
        });
      }
    }
  }

  useEffect(() => {
    fetchtTripsAverageTicket(widgetTripsSearch);
    fetchTripsVehicles(widgetTripsSearch);
    fetchTripsList(widgetTripsSearch);
    fetchPerformance(widgetTripsSearch);
    fetchTracingChart(widgetTripsSearch);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setWidgetTripsSearch({
      filter: {
        period: searchPeriod
      },
      page: widgetTripsSearch.page
    });
  }, [searchPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchtTripsAverageTicket(widgetTripsSearch);
    fetchTripsVehicles(widgetTripsSearch);
    fetchTripsList(widgetTripsSearch);
    fetchPerformance(widgetTripsSearch);
    fetchTracingChart(widgetTripsSearch);
  }, [widgetTripsSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const performanceFiltersChange = value => {
    setPerformanceFilter(value);
  };

  useEffect(() => {
    fetchPerformance(widgetTripsSearch);
  }, [performanceFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const tracingChartChange = value => {
    setTracingChartFilter(value);
  };

  useEffect(() => {
    fetchTracingChart(widgetTripsSearch);
  }, [tracingChartFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const tripsFiltersChange = value => {
    let range = {};
    setTripsFilter(value);

    if (value === 'mes-corrente') {
      range = {
        gte: `${moment()
          .startOf('month')
          .format('YYYY-MM-DD')}T00:00:00Z`,
        lte: `${moment()
          .endOf('month')
          .format('YYYY-MM-DD')}T00:00:00Z`
      };
    }
    if (value === 'semana-passada') {
      range = {
        gte: `${moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format('YYYY-MM-DD')}T00:00:00Z`,
        lte: `${moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format('YYYY-MM-DD')}T00:00:00Z`
      };
    }
    if (value === 'mes-passado') {
      range = {
        gte: `${moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')}T00:00:00Z`,
        lte: `${moment()
          .startOf('month')
          .format('YYYY-MM-DD')}T00:00:00Z`
      };
    }
    if (value === 'ultimos-3-meses') {
      range = {
        gte: `${moment()
          .subtract(30, 'months')
          .format('YYYY-MM-DD')}T00:00:00Z`,
        lte: `${moment().format('YYYY-MM-DD')}T00:00:00Z`
      };
    }
    if (value === 'hoje') {
      range = {
        gte: `${moment().format('YYYY-MM-DD')}T00:00:00Z`,
        lte: `${moment()
          .add(1, 'd')
          .format('YYYY-MM-DD')}T00:00:00Z`
      };
    }
    setSearchPeriod(range);
  };

  const tripsChangePerPage = size => {
    setWidgetTripsSearch({
      ...widgetTripsSearch.filter,
      page: {
        number: 0,
        size: size
      }
    });
  };
  const tripsChangePageNumber = page => {
    setWidgetTripsSearch({
      ...widgetTripsSearch.filter,
      page: {
        ...widgetTripsSearch.page,
        number: page
      }
    });
  };

  return (
    <DashboardTemplate
      performance={performance}
      performanceChartRange={performanceChartRange}
      performanceFilters={performanceFilters}
      performanceFiltersChange={performanceFiltersChange}
      tracingChart={tracingChart}
      tracingChartChange={tracingChartChange}
      tracingChartFilters={tracingChartFilters}
      tracingChartFiltersChange={tracingChartChange}
      trips={lastTrips}
      tripsAverageTicket={tripsAverageTicket}
      tripsChangePerPage={tripsChangePerPage}
      tripsChangePageNumber={tripsChangePageNumber}
      tripsFilters={tripsFilters}
      tripsFiltersChange={tripsFiltersChange}
      tripsItemsPerPageOptions={[5, 10, 20, 30, 40, 50]}
      tripsVehicles={tripsVehicles}
    />
  );
};

export default Dashboard;
