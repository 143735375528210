import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "red",
      color: props => props.color
    },
    textFieldStyle: {
      borderRadius: "16px",
      boxShadow: "-5px 5px 30px 0 rgba(0, 0, 0, 0.08)",
      backgroundColor: "#ffffff"
    },
    peperSize: {
      borderRadius: "14px"
    },
    buttonStyles: {
      width: "120px",
      height: "125%",
      borderRadius: "8px",
      color: "white",
      fontWeight: "bold",
      fontFamily: "ProximaNova",
      boxShadow: "0 3px 6px 0 rgba(22, 170, 231, 0.16)",
      backgroundColor: "#308bcb",
      textTransform: "capitalize"
    },
    cancelButtonStyles: {
      width: "120px",
      textTransform: "capitalize",
      fontFamily: "ProximaNova",
      height: "125%",
      color: "#909090",
      fontWeight: "bold",
      borderRadius: "8px",
      boxShadow: "0 3px 6px 0 rgba(22, 170, 231, 0.16)"
    },
    selectStyle: {
      borderBottom: '6px',
      borderRadius: "8px",
      border: "solid 2px #e7ebee",
      backgroundColor: "#ffffff",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#363636",
      marginBottom: 8,
      '& label': {
        fontSize: "16px"
      },
      '& label.Mui-focused': {
        color: '#707070',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#707070',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: "8px"
        },
        '&:hover fieldset': {
          border: "solid 2px #16aae7",
          borderRadius: "8px"
        },
        '&.Mui-focused fieldset': {
          border: "solid 2px #16aae7",
          borderRadius: "8px"
        },
      },
    },
    headingMain: {
      height: "4px",
      backgroundColor: "#e7ebee",
      border: "none",
      width: "94%"
    },
    breadcrumb: {
      display: "inline-flex",
      margin: "0% 0% 0% 4%",
      color: "#909090",
      fontFamily: "ProximaNova",
      fontSize: "inherit",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      textAlign: "left"
    },
    arrowAlign: {
      margin: "1% 1% 0% 0%"
    },
    arrow: {
      width: "16px",
      height: "16px",
      color: "gray"
    },
    breadcrumbField: {
      display: "flex",
      fontSize: "9px"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    newStyle: {
      padding: "19% 0% 0% 0%",
      margin: "-5% 1% 0% 0%",
      width: "100%"
    },
    searchIcon: {
      color: "#e7ebee"
    }
  }));
  
  export default useStyles;