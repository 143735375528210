import React, { useState, useEffect } from 'react';
import {  Popover, IconButton, Button } from '@material-ui/core';
import UserCard from './UserCard';
import { ContainerNameCompany, NameCompany } from "./styled";
import { withStyles } from '@material-ui/core/styles';
import  userProfileImage  from 'components/base/svg/icons/user_profile.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAuth } from "context";
import { withRouter } from "react-router";

const styles = theme => ({
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        fontSize: 11
    }
});

function Header(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [companyUser, setCompanyUser] = useState({});
    const { auth } = useAuth();

    useEffect(()=> {
      if(auth && ((auth || {}).company)) {
        getCompanyUser();
      }
    }, [auth])
  
    const getCompanyUser = async () => {
      try {
        const { company } = auth;
        setCompanyUser(company);
      } catch(error) {
        console.log(error);
      }
    }
  
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const goPushCompaniesPage = () => {
        if(auth.roles.indexOf('master') == -1) {
            return;
        }
        props.history.push('/companies')
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <React.Fragment>
            <ContainerNameCompany>
                <Button onClick={goPushCompaniesPage}><NameCompany>{companyUser.name}</NameCompany></Button>
                <IconButton edge="end" color="inherit" aria-haspopup="true" aria-describedby={id} variant='contained' onClick={handleClick}>
                <AccountCircle style={{color:'#091c3a'}}/>
                </IconButton>
            </ContainerNameCompany>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <UserCard
                    onLogoutClick={e => props.onLogoutClick()}
                    className='usercardicon-tooltip'
                    userInfo={props.UserCard}
                />

            </Popover>
        </React.Fragment>
    );
}

Header.defaultProps = {
    icons: {
        color: 'grey',
        name: 'account_circle',
        backgroundColor: 'none',
        className: 'material-icons profile-material-icons',
        styleprops: {
            ListComponent: 'nav'
        }
    },
    UserCard: {
        userName: 'John Doe',
        email: 'john_doe@xyz.com',
        userRole: 'Registry Admin',
        iconUrl: userProfileImage,
        onClick: e => {},
        visibility: {
            editProfile: false
        }
    },
    onLogoutClick: e => {
        // // console.log(("header")
    }
};

export default withRouter(withStyles(styles)(Header));
