import { get } from 'services/api';

const getTracingChartsByCostCenter = async (config) => await get({
  type: 'trips',
  service: 'trips-per-cost-center',
  queryString: config
});

const getTracingChartsByDepartment = async (config) => await get({
  type: 'trips',
  service: 'trips-per-department',
  queryString: config
});

const getTrips = async (config) => await get({
  type: 'trips',
  service: 'trip',
  queryString: config
});

const getTripsAverageTicket = async (config) => await get({
  type: 'trips',
  service: 'average-ticket',
  queryString: config
});

const getTripsVehicles = async (config) => await get({
  type: 'trips',
  service: 'trips-per-model',
  queryString: config
});

const getPerformanceByCost = async (config) => await get({
  type: 'trips',
  service: 'daily-cost',
  queryString: config
});

const getPerformanceByTrips = async (config) => await get({
  type: 'trips',
  service: 'daily-trips',
  queryString: config
});

export default {
  getTracingChartsByCostCenter,
  getTracingChartsByDepartment,
  getTrips,
  getTripsAverageTicket,
  getTripsVehicles,
  getPerformanceByCost,
  getPerformanceByTrips
};



