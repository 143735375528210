import { get, patch, put } from "services/api";

const CompanyService = {}

CompanyService.find = async () => {
    return await get({ type: 'user', service: 'company', queryString: [] })
}

CompanyService.findAll = async () => {
    return await get({ type: 'user', service: 'companies', queryString: [] })
}

CompanyService.changeTokenCompany = async (id) => {
    return await put({ type: 'user', service: `master/change/${id}`})
}

CompanyService.update = async (company) => {
    return await patch({ type: "user", service: 'company', data: company })
}

CompanyService.block = async (id) => {
    return await patch({ type: "user", service: `company/${id}/block`})
}

CompanyService.unblock = async (id) => {
    return await patch({ type: "user", service: `company/${id}/unblock`})
}

export default CompanyService