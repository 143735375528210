import React from 'react';
import PropTypes from 'prop-types';

import SidebarTemplate from 'components/templates/Sidebar';

import WidgetPerformance from 'components/molecules/WidgetPerformance';
import WidgetTracingChart from 'components/molecules/WidgetTracingChart';
import WidgetTrips from 'components/molecules/WidgetTrips';

import './styles.sass';

const DashboardTemplate = props => {

  return (
    <SidebarTemplate>
      <div className="dashboard-page">
        <div className="widgets-container">
          <div className="widgets-container--left">
            <WidgetTrips
              averageTicket={props.tripsAverageTicket}
              changePerPage={props.tripsChangePerPage}
              changePageNumber={props.tripsChangePageNumber}
              data={props.trips}
              filters={props.tripsFilters}
              filtersChange={props.tripsFiltersChange}
              vehicles={props.tripsVehicles}
            />
          </div>
          <div className="widgets-container--right">
            <WidgetPerformance
              data={props.performance}
              filtersChange={props.performanceFiltersChange}
              filters={props.performanceFilters}
              chartRange={props.performanceChartRange}
            />
            <WidgetTracingChart
              chartRange={props.tracingChartRange}
              filters={props.tracingChartFilters}
              filtersChange={props.tracingChartFiltersChange}
              data={props.tracingChart}
            />
          </div>
        </div>
      </div>
    </SidebarTemplate>
  );
}

DashboardTemplate.propTypes = {
  performance: PropTypes.array,
  performanceChartRange: PropTypes.object,
  performanceFilters: PropTypes.array,
  performanceFiltersChange: PropTypes.func,
  tracingChart: PropTypes.array,
  tracingChartFiltersChange: PropTypes.func,
  tracingChartRange: PropTypes.object,
  trips: PropTypes.object,
  tripsAverageTicket: PropTypes.object,
  tripsChangePerPage: PropTypes.func,
  tripsChangePageNumber: PropTypes.func,
  tripsFiltersChange: PropTypes.func
}

DashboardTemplate.defaultProps = {
  performance: [],
  performanceChartRange: {},
  performanceFilters: [],
  performanceFiltersChange: () => {},
  tracingChart: [],
  tracingChartFiltersChange: () => {},
  tracingChartRange: {},
  trips: {},
  tripsAverageTicket: {},
  tripsChangePerPage: () => {},
  tripsChangePageNumber: () => {},
  tripsFiltersChange: () => {}
};

export default DashboardTemplate;
