import React, { useState, useEffect } from 'react';
import { Grid, Card, FormControl, Button, Typography, MenuItem, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import SidebarTemplate, { WrapperContent } from "components/templates/Sidebar";
import { TitlePage } from "../../atoms/StyledsBase/styledIndex";
import ReportTripsServices from '../../../services/reportTrips.service';
import brLocale from 'date-fns/locale/pt-BR';
import {
    DatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import moment from 'moment';
import downloadCsv from 'download-csv';

const ReportFinances = () => {
    const [payload, setPayoad] = useState({
        start: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        end: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
    })

    useEffect(() => {
        handleChange('end')(moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"));
    }, [payload.start]);

    const handleChange = (field) => (value) => {
        setPayoad({ ...payload, [field]: value })
    };

    const onSave = async (data, e) => {
        try {
            download(await ReportTripsServices.getReport(payload))
        } catch (error) {
            console.log(error, 'a')
      }
    };

    const download = ({data}) => {
        downloadCsv(data,data, `Relatorio.${payload.start}.${payload.end}.csv`);
    }

    return (

        <SidebarTemplate>
            <WrapperContent >
                <Grid item xs={12} className="title"><TitlePage>Relatório de viagens</TitlePage></Grid>

                <Card style={{ marginTop: 20, width: '100%', padding: 30, flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FormControl
                        style={{ width: '70%' }}>
                        <Grid>
                            <Grid>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency"
                                    variant="outlined"
                                    select
                                    label="Tipo de relatório"
                                    value={payload.type}
                                    onChange={(e) => handleChange('type')(e.target.value)}
                                >
                                    {[{ value: 'reportTrips', label: 'Relatório de viagens' }].map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <Grid style={{flex: 1, marginRight: 6}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                                        <DatePicker
                                            fullWidth
                                            name="Escolha"
                                            label="Escolha a data de incio"
                                            inputVariant="outlined"
                                            value={payload.start}
                                            maxDate={moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}
                                            onChange={(data) => handleChange('start')(data)}
                                            ampm={false}
                                            clearLabel="Limpar"
                                            cancelLabel="Cancelar"
                                            format="dd/MM/yyyy"
                                            error=''
                                            helperText=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid style={{flex: 1, marginRight: 6}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                                        <DatePicker
                                            fullWidth
                                            name="Escolha"
                                            label="Escolha a data de fim"
                                            inputVariant="outlined"
                                            value={payload.end}
                                            maxDate={moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}
                                            minDate={payload.start}
                                            onChange={(data) => handleChange('end')(data)}
                                            ampm={false}
                                            clearLabel="Limpar"
                                            cancelLabel="Cancelar"
                                            format="dd/MM/yyyy"
                                            error=''
                                            helperText=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid style={{flex: 1}}>
                                    <Button style={{ backgroundColor: '#308ACB' }} onClick={onSave} color="primary" variant="contained">PROCESSAR</Button>
                                </Grid>
                            </Grid>
                   

                        </Grid>


                    </FormControl>
                </Card>
            </WrapperContent>
        </SidebarTemplate>
    )


}
export default ReportFinances;