import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Button} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import Radio from '@material-ui/core/Radio'
import { useAuth } from "context";
import moment from 'moment';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ListAltOutlined from '@material-ui/icons/ListAltOutlined';
import SidebarTemplate from "components/templates/Sidebar";
import ListGrid from "components/organisms/ListGrid";
import CustomizedMenus from 'components/organisms/ListGrid/CustomizedMenus';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import FinanceService from "services/finance.service"
import { TitlePage } from "../../atoms/StyledsBase/styledIndex";
import Debits from './Debits';
import toastr from 'toastr';
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCustom = withStyles((theme) => ({
	root: {
	  padding: theme.spacing(2),
	},
	paper: {
		minWidth: 620
	}
}))(Dialog);

function FinancePage() {

    const [invoices, setInvoicesSate] = useState([])
    const [selectedInvoice, selectInvoice] = useState(false);
    const [modalUpdateBillet, setModalUpdateBillet] = useState({});
    const [dueDate, setDueDate] = useState(moment());

    const { auth } = useAuth();

    useEffect(() => {
        getInvoices();
    }, []);

    useEffect(() => {
        console.log(moment(dueDate).utc().format('YYYY-MM-DDT00:00:00[Z]'))
    }, [dueDate]);


    const getInvoices = async () => {
        let invoices = await FinanceService.findAll()
        setInvoicesSate(invoices);
    }

    const aprovePaid = async (invoice) => {
       await FinanceService.aprovePaid(invoice.id);
       let invoices = await FinanceService.findAll()
       setInvoicesSate(invoices);
    }

    async function sendUpdate(id) {
        await FinanceService.updateBillet({id, dueDate: moment(dueDate).format('YYYY-MM-DDT00:00:00[Z]')});
        let invoices = await FinanceService.findAll();
        setInvoicesSate(invoices);
        toastr.success("Data de vencimento atualizada com sucesso !");
        setModalUpdateBillet({});
    }

    const getDataTable = () => ({
        header: [
            { flex: 1, label: 'Fatura' }, { flex: 1, label: "Vencimento" },
            { flex: 1, label: "Valor" }, { flex: 2, flexDirection: 'column', label: 'Status' },
            { flexDirection: 'column', label: "Ações" }
        ],
        rows: getRows(invoices)
    })

    const getRows = (invoices) => {
        return (invoices || []).reduce((rows, invoice) => {
            rows.push([
                { flex: 1, label: invoice.id }, { flex: 1, label: moment(new Date(invoice.dueDate)).format('DD/MM/YYYY') },
                { flex: 1, label: `R$${invoice.value}` },
                { flex: 2, flexDirection: 'row', children: () => getStatusElement(invoice.status) },
                { flexDirection: 'column', children: () => (<CustomizedMenus id={invoice} items={renderMenuActions(invoice)} />) }
            ])
            return rows
        }, [])
    }

    const renderMenuActions = (invoice) => {
        const allowedAprovedPaid = !(invoice.status !== 'pago' && (((auth || {}).roles || []).includes('master')));
        const allowedUpdateDueBillet = !(invoice.status !== 'pago' && (moment(invoice.boletoDueDate).diff(new Date(), 'days') <= 0))
        return [
        {
            label: "Baixar boleto de cobrança",
            element: () => (<PlayForWorkIcon />),
            onClick: (invoice) => FinanceService.downloadPaymentSlip(invoice)
        },
        {
            label: "Atualizar vencimento do boleto",
            show: allowedUpdateDueBillet,
            element: () => (<SystemUpdateAltIcon />),
            onClick: (invoice) => !allowedUpdateDueBillet && setModalUpdateBillet({invoice, open: true})
        },
        {
            label: 'Baixar nota de débito', element: () => (<PlayForWorkIcon />),
            onClick: (invoice) => FinanceService.downloadDebitFile(invoice.id)
        },
        {
          label: 'Ver descritivo', element: () => (<ListAltOutlined />),
          onClick: (invoice) => selectInvoice(invoice)
        },
        {
            confirm: true,
            show: allowedAprovedPaid,
            label: "Reconhecer pagamento", 
            element: () => (<PaymentOutlinedIcon />),
            onClick: (invoice) => !allowedAprovedPaid && aprovePaid(invoice)
        }
    ]}

    const getStatusElement = (status) => {
        let statusColor = {
            'criado': 'yellow', 'aguardando pagamento': 'yellow',
            'pago': 'green', 'pendente': 'red', 'expirado': 'red'
        }
        let color = {
            green: '#30cb8f', yellow: "#e7b800", red: "#DC004E"
        }[statusColor[status]]
        return (
            <>
                <Tooltip title={status} aria-label={status}>
                    <Radio style={{ 'color': color }} checked="checked" />
                </Tooltip> <span>{status.toUpperCase()}</span>
            </>
        )
    }

    return (
        <SidebarTemplate>
            <div className="planos-wrapper">
                {
                    !selectedInvoice ? <>
                         <DialogCustom
                            open={modalUpdateBillet.open}
                            onClose={() => setModalUpdateBillet({})}
                            keepMounted
                            TransitionComponent={Transition}
                        >
                            <DialogTitle>Atualizar vencimento do Boleto de cobrança</DialogTitle>
                            <DialogContent style={{marginTop: 20, justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                                    <DatePicker
                                        autoOk
                                        orientation="landscape"
                                        variant="static"
                                        openTo="date"
                                        minDate={new Date()}
                                        format="2020-06-04T00:00:00"
                                        value={dueDate}
                                        onChange={setDueDate}
                                    />
                                </MuiPickersUtilsProvider>    
                            </DialogContent>
                            <DialogActions style={{marginTop: 10}}>
                                <Button autoFocus onClick={() => setModalUpdateBillet({})} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={() => sendUpdate(modalUpdateBillet.invoice?.id)} color="primary">
                                    Atualizar
                                </Button>
                            </DialogActions>
                        </DialogCustom>
                        <Grid item xs={12} className="title"><TitlePage>Financeiro</TitlePage></Grid>
                        <div>
                            <ListGrid dataTable={getDataTable()} />
                        </div>
                    </>
                    : <Debits selectInvoice={selectInvoice} invoice={selectedInvoice} />
                }
            </div>
        </SidebarTemplate>
    )

}

export default FinancePage;