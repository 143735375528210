import styled from 'styled-components';

export const LoadContainer = styled.div`
    display: ${({ loading }) =>
      Boolean(loading) === false ? 'block' : 'none'}; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 50000; /* Sit on top */
    padding-top: 26%; /* Location of the box */
    padding-left: 50%
    left: 0;
    top: 0;
    min-width: 100%; /* Full width */
    min-height: 120%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`;
