export const employeeStatus = (value) => {
    if (!value) {
      return ''
    }
    else if (value === "Ativo") {
        return "greenDot"
    }
    else if (["Pendente", "Validando", "Cadastrando", "Convidado"].includes(value)) {
        return "yellowDot"
    }
    else if (["Bloqueado", "Excluído"].includes(value)) {
        return "redDot"
    }
    else {
      return "invalid status"
    }
  }
  
  export default {
    employeeStatus
  }