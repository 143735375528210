import React, { useState, useEffect, createRef } from "react";
import PropTypes from "prop-types";
import SidebarTemplate from "components/templates/Sidebar";
import Button from "@material-ui/core/Button";
import PageSearch from "components/molecules/PageSearch";
import ListGrid from "components/organisms/ListGrid";
import Pagination from "components/molecules/Pagination";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import FormContributors from "components/templates/Contributors/FormContributors/index";
import { Delete, HttpsOutlined, LockOpenOutlined, MailOutline } from '@material-ui/icons'
import { makeStyles, withStyles, Tooltip, Grid, Typography } from "@material-ui/core";
import Radio from '@material-ui/core/Radio'
import { employeeStatus } from "helpers/status";
import EditIcon from "@material-ui/icons/Edit";
import CustomizedMenus from 'components/organisms/ListGrid/CustomizedMenus';
import EmployeeService from "services/contributor.service";
import { formatBRL } from "helpers/money";
import { ContainerPage, TopContainerPage, ContentPage, FooterPage, TitlePage, FloatButton } from "../../atoms/StyledsBase/styledIndex";
import ContributorUploadService from 'services/contributor.upload.service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { green } from '@material-ui/core/colors';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { ContainerDragFile, ContainerCircleCountUploads, TextButtonUpload, NumberCircleCountUploads, TextCircleCountUploads, ContainerMgsErros } from "./styled";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MuiAlert from '@material-ui/lab/Alert';
import ListAltIcon from '@material-ui/icons/ListAlt';

const ColorButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "red",
    color: props => props.color
  },
  demo: {
    borderRadius: "16px",
    boxShadow: "-5px 5px 30px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "#ffffff"
  },
  peperSize: {
    color: "#909090"
  },
  rootCollapse: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ContributorsTemplate = ({
  children,
  contributors,
  searchConfig,
  setSearchConfig,
  onRefresh,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [toogleScreen, setToogleScreen] = useState(false);
  const [employeeIdEdit, setEmployeeIdEdit] = useState(false);
  const [OverDragSpace, setOverDragSpace] = useState(false);
  const [showFirtsStep, setShowFirtsStep] = useState(true);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [fileUpload, setFileUpload] = useState({});
  const [nameFile, setNameFile] = useState("");
  const classes = useStyles(props);

  useEffect(() => {
    if (toogleScreen === false) {
      setEmployeeIdEdit(false);
    }
  }, [toogleScreen])


  const DataTable = () => {
    return {
      header: [
        { flex: 2, label: "Name" },
        { flex: 2, label: "Departamento" },
        { flex: 2, label: "Limite mensal" },
        { flex: 1, label: "Plano" },
        { flex: 1, label: "Status", flexDirection: 'column' },
        { flex: 1, label: "Editar", flexDirection: 'column' },
        { flex: 1, label: "Ações", flexDirection: 'column' },
      ],
      rows: MountRowsTable(contributors.content)
    }
  }

  const MountRowsTable = (rows) => {
    let rowsMount = [];
    (rows || []).map((row => {
      rowsMount.push([
        {
          flex: 2,
          label: `${row.name}`,
          onClick: "",
          children: ""
        },
        {
          flex: 2,
          label: `${row.department || 'Não cadastrado'}`,
          onClick: "",
          children: ""
        },
        {
          flex: 2,
          label: `${formatBRL(row.monthlyLimit) || formatBRL('0000')}`,
          onClick: "",
          children: ""
        },
        {
          flex: 1,
          label: `${row.limit || ''}`,
          onClick: "",
          children: ""
        },
        {
          flex: 1,
          label: '',
          onClick: "",
          children: () => renderStatusElement(row.status),
          flexDirection: 'column'
        },
        {
          flex: 1,
          label: ``,
          onClick: "",
          children: () => renderEditButton(row.id),
          flexDirection: 'column'

        },
        {
          flex: 1,
          label: "",
          onClick: "",
          children: () => (<CustomizedMenus id={row.id} items={renderMenuActions(row)} />),
          flexDirection: 'column'
        },
      ]);
    }));
    return rowsMount;
  }


  const getColapse = () => {

    const getItemError = () => {
      return (fileUpload.invalid || []).map(obj =>(
        <ListItem key={obj.cpf} button className={classes.nested}>
          <ListItemText>CPF: <b>{obj.cpf || ""}</b> {obj.message}</ListItemText>
        </ListItem>
    ))
  }

    const handleClick = () => {
      setOpenCollapse(!openCollapse);
    };

    return (
      <DialogContentText>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.rootCollapse}
      >
        <ListItem button style={{marginBottom: 10}}>
          <ListItemIcon>
            <NumberCircleCountUploads width={50} bottom={0} color='#4CAF50'right={10}><b>{(Number(fileUpload.created || 0) + Number(fileUpload.updated || 0))}</b></NumberCircleCountUploads>
          </ListItemIcon>
          <ListItemText><b>Solicitações de cadastro aptos </b>({fileUpload.created || 0} cadastrados, {fileUpload.updated || 0} atualizações)</ListItemText>
        </ListItem>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <NumberCircleCountUploads width={50} color='#F44335' bottom={0} right={10}><b>{(fileUpload.invalid || []).length}</b></NumberCircleCountUploads>
          </ListItemIcon>
          <ListItemText><b>Rejeitados</b></ListItemText>
          {(openCollapse && ((fileUpload.invalid || []).length !== 0)) ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={(openCollapse && ((fileUpload.invalid || []).length !== 0))} timeout="auto" unmountOnExit>
          <ContainerMgsErros>
          {(fileUpload.invalid || []).length !== 0 ? getItemError() : false}
          </ContainerMgsErros>
        </Collapse>
      </List>
      </DialogContentText>
    );
  }

  const getSecondpDialog = () => (
    <>
      <DialogContentText id="alert-dialog-slide-description">
        <ContainerCircleCountUploads id="aaaa">
          <NumberCircleCountUploads><b>{(Number(fileUpload.created || 0) + Number(fileUpload.updated || 0) + Number((fileUpload.invalid || []).length))}</b></NumberCircleCountUploads>
          <TextCircleCountUploads><b>Colaboradores na Planilha</b></TextCircleCountUploads>
        </ContainerCircleCountUploads>
      </DialogContentText>
      {getColapse()}
    </>
  )

  const getFirstStepDialog = (msgs) => (
    <>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        <b>Preencha a planilha modelo</b>
      </DialogContentText>
      <DialogContentText id="alert-dialog-slide-description">
        Ultilize a planilha modelo para realizar a importação em massa dos colaboradores
      </DialogContentText>
      <DialogContentText id="alert-dialog-slide-description">
        <b>Faça o download da planilha abaixo</b>
      </DialogContentText>
      <DialogContentText id="alert-dialog-slide-description">
        <ColorButton onClick={(e) => getModelExcel(e)} style={{backgroundColor: '#027748'}} variant="contained" color="primary" startIcon={<FormatListBulletedIcon />} className={classes.margin}>
          Baixar planilha modelo
        </ColorButton>
      </DialogContentText>
      <DialogContentText id="alert-dialog-slide-description">
        <b>Agora, importe a planilha de colaboradores</b>
      </DialogContentText>
      <DialogContentText id="alert-dialog-slide-description">
        Clique em <b>importar planilha de colaboradores</b> para procurá-la no seu computador, ou arraste a planilha para o campo cinza.
      </DialogContentText>
      <ContainerDragFile onDrop={(e) => getByFile(e)} OverDragSpace={OverDragSpace} onDragLeave={(e) => onDragLeave(e)}  onDragExit={(e) => onDragExit(e)} onDragOver={(e) => onOverDragSpace(e)}>
        <input accept=".xlsx" style={{ 'display': 'none' }} id="contributorsUpload" type="file" onChange={(e) => getByFile(e)} />
        {(Object.keys(fileUpload).length === 0) || (fileUpload.error) ? (
        <> 
          <label style={{display: 'flex', alignSelf: 'center'}} htmlFor="contributorsUpload">
            <Button style={{backgroundColor: '#308ACB'}}variant="contained" color="primary" component="span"><FormatListBulletedIcon /><TextButtonUpload>Importar planilha de colaboradores</TextButtonUpload></Button>
          </label>
        </>
        ) : (
        <Grid style={{display:'flex',  alignSelf: 'center'}}>
          <ListAltIcon style={{marginRight: 4, fontSize: 32}}/> 
          <Typography fontSize="large" variant="span" component="h3">
            {nameFile}
          </Typography>
        </Grid>)}
      </ContainerDragFile>
      <DialogContentText style={{color: '#F44335'}} id="alert-dialog-slide-description">
        {fileUpload.error ? `Essa planilha está vazia. Adicione os colaboradores na planilha modelo para cadastrá-los.` : '' }
      </DialogContentText>
  </DialogContent>
  </>
  );

  const getDialog = () => {
    const handlerCloseDialog = () => {
      setNameFile("");
      setFileUpload({});
      setOpenDialog(false)
      setShowFirtsStep(true);
    };

    let handlerNextStep = async () => {
      try {
        if(!showFirtsStep) {
          onRefresh();
          return handlerCloseDialog();
        }
        if((fileUpload.valid || []).length !== 0) {
          let {data} = await EmployeeService.saveImport(fileUpload.valid);
          setFileUpload({...fileUpload, invalid: [...fileUpload.invalid, ...(data.failure || [])], ...data})
        }
        setShowFirtsStep(false);
      } catch(error) {
        console.log(error);
      }
    }
    let disabledButton = !((fileUpload.valid || []).length || (fileUpload.invalid || []).length);
    return (
      <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title"><b>Importar colaboradores em massa</b></DialogTitle>
      {showFirtsStep ? getFirstStepDialog() : getSecondpDialog()}
      <DialogActions>
        <Button style={{color: '#308ACB'}} onClick={handlerCloseDialog} component="span">Cancelar</Button>
        <Button style={{color: '#ffffff', backgroundColor:`${disabledButton ? '' : '#308ACB'}`}} disabled={disabledButton} variant="contained" component="span" onClick={handlerNextStep}>Prosseguir</Button>
      </DialogActions>
    </Dialog>
    )
  }

  const renderEditButton = (id) => (<EditIcon className={classes.peperSize} onClick={() => { setEmployeeIdEdit(id); setToogleScreen(true); }} />)

  const renderStatusElement = (status) => (
    <Tooltip title={status} aria-label={status}>
      {employeeStatus(status) == "greenDot" ?
        <GreenRadio
          checked="checked"
        /> :
        employeeStatus(status) == "yellowDot" ?
          <YellowRadio
            checked="checked"
          /> :
          <RedRadio
            checked="checked"
          />}
    </Tooltip>
  )

  const GreenRadio = withStyles({
    root: {
      color: "#30cb8f",
      '&$checked': {
        color: "#30cb8f",
      },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />)

  const RedRadio = withStyles({
    root: {
      color: "#DC004E",
      '&$checked': {
        color: "#DC004E",
      },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />)

  const YellowRadio = withStyles({
    root: {
      color: "#e7b800",
      '&$checked': {
        color: "#e7b800",
      },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />)

  const disableMenuItem = (action, status) => {

    let disabledTypes = [{ status: 'Bloqueado', action: 'Bloqueado' },
    { status: 'Ativo', action: 'Desbloquear' }, { status: 'Excluido', action: 'Excluir' }]

    return disabledTypes.some(item => (item.status == status && item.action == action))
  }

  const renderMenuActions = (row) => {
    return [
      {
        label: "Bloquear",
        confirm: true,
        show: disableMenuItem("Bloquear", row.status),
        element: () => (<LockOpenOutlined style={{ color: '#909090' }} />),
        onClick: (id) => blockContributors(id)
      },
      {
        label: "Enviar email boas vindas",
        confirm: true,
        element: () => (<MailOutline style={{ color: '#909090' }} />),
        onClick: (id) => sendEmail(id)
      },
      {
        label: "Desbloquear",
        confirm: true,
        show: disableMenuItem("Desbloquear", row.status),
        element: () => (<HttpsOutlined style={{ color: '#909090' }} />),
        onClick: (id) => unBlockContributors(id)
      },
      {
        label: "Excluir",
        confirm: true,
        show: disableMenuItem("Excluir", row.status),
        element: () => (<Delete style={{ color: '#909090' }} />),
        onClick: (id) => excludeContributors(id)
      }
    ]
  }

  const buttonSortHandler = e => {
    setSearchConfig({
      page: searchConfig.page,
      sort: {
        desc: !searchConfig.sort.desc,
        field: searchConfig.sort.field
      }
    });
  };

  const handlerSearch = e => {
    setSearchConfig({
      search: e.target.value,
      page: {
        number: 0,
        size: searchConfig.page.size
      },
      sort: searchConfig.sort
    });
  };

  const handlerChangePerPage = perPage => {
    setSearchConfig({
      page: {
        number: 0,
        size: perPage
      },
      sort: searchConfig.sort
    });
  };

  const handlerPageNumber = page => {
    setSearchConfig({
      page: {
        number: page,
        size: searchConfig.page.size
      },
      sort: searchConfig.sort
    });
  };

  const blockContributors = async (id) => {
    try {
      const employee = { statusId: 3, id };
      const res = await EmployeeService.edit(employee);
      onRefresh();
    } catch (error) {
      console.error(error)
    }
  }

  const sendEmail = async (id) => {
    try {
      const employee = { statusId: 3, id };
      const res = await EmployeeService.sendEmail(employee);
      onRefresh();
    } catch (error) {
      console.error(error)
    }
  }

  const unBlockContributors = async (id) => {
    try {
      const employee = { statusId: 1, id };
      const res = await EmployeeService.edit(employee);
      onRefresh();
    } catch (error) {
      console.error(error)
    }
  }

  const excludeContributors = async (id) => {
    try {
      const employee = { statusId: 2, id };
      await EmployeeService.edit(employee);
      onRefresh();
    } catch (error) {
      console.error(error)
    }
  }

  const onOverDragSpace = (event) => {
    event.preventDefault();
    setOverDragSpace(true)
  }

  const onDragLeave = (event) => {
    event.preventDefault();
    setOverDragSpace(false)
  }

  const onDragExit = (event) => {
    event.preventDefault();
    setOverDragSpace(false)
  }

  const getByFile = async (event) => {
    event.preventDefault();
    setOverDragSpace(false);
    setFileUpload({})
    setNameFile("");
    let file = (event.target.files || event.dataTransfer.files)[0];
    setNameFile(file.name);
    if (!file || file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      clearFileUpload()
      return
    }
    try {
      let result = await ContributorUploadService.getByFile(file);
      setFileUpload(result);
      clearFileUpload();
    } catch (error) {
      setFileUpload(error);
      console.error('error get users from file upload', error)
      clearFileUpload()
    }
  }

  const clearFileUpload = () => (
    document.getElementById('contributorsUpload').value = ""
  )

  const getModelExcel = (event) => {
    event.preventDefault();
    ContributorUploadService.download()
  }

  return (
    <SidebarTemplate>
      <ContainerPage>
        {toogleScreen ? (
          <FormContributors onRefresh={onRefresh} employeeIdEdit={employeeIdEdit} setToogleScreen={setToogleScreen} />
        ) : (
            <>
            {getDialog()}
              <FloatButton>
                <Fab
                  style={{backgroundColor: '#308ACB'}}
                  color="primary"
                  aria-label="add"
                  onClick={() => setToogleScreen(true)}
                >
                  <AddIcon />
                </Fab>
              </FloatButton>
              <TopContainerPage>
                <TitlePage>Colaborador</TitlePage>
                <Grid style={{ 'marginRight': '10px' }}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start">
                  <Grid item>
                    <Button style={{backgroundColor: '#308ACB'}} variant="contained" onClick={() => setOpenDialog(true)} color="primary" component="span">Importar em massa</Button>
                  </Grid>
                </Grid>
              </TopContainerPage>
              <ContentPage>
                <ListGrid
                  context="Colaborador"
                  dataTable={DataTable()}
                />
              </ContentPage>
              <FooterPage>
                {contributors && contributors.page ? (
                  <Pagination
                    changePerPage={handlerChangePerPage}
                    changePageNumber={handlerPageNumber}
                    itemsPerPageOptions={props.itemsPerPageOptions}
                    page={contributors.page.number}
                    perPage={contributors.page.size}
                    totalItems={contributors.page.items}
                    totalPages={contributors.page.range}
                  />
                ) : (
                    ""
                  )}

              </FooterPage>

            </>
          )}
      </ContainerPage>
    </SidebarTemplate>
  );
};

ContributorsTemplate.propTypes = {
  itemsPerPageOptions: PropTypes.array,
  searchConfig: PropTypes.object,
  setSearchConfig: PropTypes.func
};

ContributorsTemplate.defaultProps = {
  itemsPerPageOptions: [20, 30],
  searchConfig: {},
  setSearchConfig: () => { }
};

export default ContributorsTemplate;
