import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as ChevronLeftIcon } from 'components/base/svg/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'components/base/svg/icons/chevron-right.svg';

import './styles.sass';

const Pagination = ({ page, perPage, itemsPerPageOptions, totalItems, totalPages, ...props}) => {

  // NOTES: api returns page initialized in zero.

  const [hasPageNext, setHasPageNext] = useState(false);
  const [hasPagePrev, setHasPagePrev] = useState(false);
  const [pageInterface, setPageInterface] = useState(page + 1);
  const [firstItemPage, setFirstItemPage] = useState(1);
  const [lastItemPage, setLastItemPage] = useState(1);

  useEffect(() => {

    setPageInterface(page + 1);
    setFirstItemPage(1);
    setLastItemPage(1);

    if (totalPages > 1) {
      if (pageInterface !== 1) {
        setFirstItemPage(((pageInterface-1) * perPage) + 1);
        setHasPagePrev(true);
      } else {
        setHasPagePrev(false);
      }

      if (totalPages === pageInterface) {
        if (totalItems % perPage === 1) {
          setLastItemPage('');
        } else {
          setLastItemPage(totalItems);
        }
        setHasPageNext(false);
      } else {
        setLastItemPage(perPage * pageInterface);
        setHasPageNext(true);
      }
    } else {
      setLastItemPage(totalItems);
    }

  }, [page, pageInterface, perPage, totalItems, totalPages]);

  const handlerChangePerPage = (e) => {
    props.changePerPage(parseInt(e.target.value));
  }

  const handlerGoNext = (e) => {
    if (hasPageNext) {
      props.changePageNumber(page + 1);
    }
  }

  const handlerGoPrev = (e) => {
    if (hasPagePrev) {
      props.changePageNumber(page - 1);
    }
  }

  return (
    <div className="pagination">
      <div className="pagination__per-page">
        <span className="pagination__per-page__label">Itens por página:</span>
        <select className="pagination__per-page__dropdown"
          defaultValue={perPage}
          onChange={handlerChangePerPage}
        >
          {itemsPerPageOptions.map(function(qtd, index){
            return <option key={qtd} value={qtd}>{qtd}</option>;
          })}
        </select>
      </div>
      <div className="pagination__size">
        {`${firstItemPage}${lastItemPage !== '' ? ` - ${lastItemPage}`: ''} de ${totalItems}`}
      </div>
      <div className="pagination__navigation">
        <span
          className={
            classNames("pagination__navigation__button", {
              "pagination__navigation__button--active": hasPagePrev,
              "pagination__navigation__button--inactive": !hasPagePrev
          })}
          onClick={handlerGoPrev}
        >
          <ChevronLeftIcon />
        </span>
        <span
          className={
            classNames("pagination__navigation__button", {
              "pagination__navigation__button--active": hasPageNext,
              "pagination__navigation__button--inactive": !hasPageNext
          })}
          onClick={handlerGoNext}
        >
          <ChevronRightIcon />
        </span>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  changePerPage: PropTypes.func.isRequired,
  changePageNumber: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  itemsPerPageOptions: PropTypes.array,
  totalItems: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
}

Pagination.defaultProps = {
  changePerPage: () => {},
  changePageNumber: () => {},
  page: 0,
  perPage: 0,
  itemsPerPageOptions: [20, 30, 40, 50],
  totalItems: 0,
  totalPages: 0
};


export default Pagination;