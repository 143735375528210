import styled from "styled-components";

export const ContainerDragFile = styled.div`
    display: flex;
    justify-content: center;
    min-width: 200px;
    min-height: 150px;
    background-color: #F0F0F0;
    ${({OverDragSpace}) => OverDragSpace && `
        outline: dotted;
        opacity: 0.2;
    `}
`;

export const ContainerCircleCountUploads = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const NumberCircleCountUploads = styled.span`
    line-height: 1.6;
    margin-left: 9px;
    text-align: center;
    width: ${({width = 60}) => width}px;
    height: 25px;
    margin-bottom: ${({bottom = 10}) => bottom}px;
    margin-right: ${({right = 0}) => right}px;
    border-radius: 10px;
    background-color: ${({color = '#d2d2d2'}) => color};
    overflow: hidden;
`;

export const TextCircleCountUploads = styled.span`
    line-height: 1.6;
    margin-left: 9px;
    text-align: center;
    font-weight: bold;
`;

export const ContainerMgsErros = styled.div`
    max-height: 280px;
    overflow: scroll;
`;

export const TextButtonUpload = styled.span`
    line-height: 1.6;
    margin-left: 9px;
`;