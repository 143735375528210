import React, { useEffect, useState } from 'react';
import ContributorsTemplate from 'components/templates/Contributors';
import ContributorsService from 'services/contributor.service';
import { ContributorsContext, useAuth } from 'context';

const ContributorsPage = props => {

  const { setAuth } = useAuth();
  const [canFetchData, setCanFetchData] = useState(true);

  const [contributors, setContributors] = useState({});

  const [searchConfig, setSearchConfig] = useState({
    page: {
      number: 0,
      size: 20,
    },
    sort: {
      desc: false,
      field: 'name'
    }
  });

  useEffect(() => {
    if(searchConfig.search) {
      search()
    } else {
      setCanFetchData(true);
    }
  }, [searchConfig]);

  // Make Request!
  useEffect(() => {
    if (canFetchData) {
      findAllEmployee({
        searchConfig,
        setContributors
      });
      setCanFetchData(false);
    }
  }, [canFetchData]); // eslint-disable-line react-hooks/exhaustive-deps

  const search = () => {
    if(searchConfig.search) {
      let content = (contributors.content || []).filter(col => { 
        if(JSON.stringify(col).toUpperCase().indexOf(searchConfig.search.toUpperCase()) !== -1) {
          return col
        }
     });
     return {...contributors, content: content};
    }
    return contributors;
  };

  const findAllEmployee = async ({ searchConfig, setContributors }) => {
    try {
      const res = await ContributorsService.findAll(searchConfig);
      if (res.status === 200) {
        setContributors(res.data)
      }
      if (res.status === 204) {
        setContributors({
          content: []
        })
      }
      if (res.status === 401) {
        setAuth();
      } 
    } catch (error) {
      if (error.status === 401) {
        setAuth();
      }  
    }
  }

  return (
    <>
      <ContributorsContext.Provider
        value={{
          contributors,
          setContributors
        }}
      >
        <ContributorsTemplate
          contributors={search()}
          itemsPerPageOptions={[5, 10, 20, 30, 40, 50]}
          searchConfig={searchConfig}
          onRefresh={() => findAllEmployee({
            searchConfig,
            setContributors
          })}
          setSearchConfig={setSearchConfig}
        />
      </ContributorsContext.Provider>
    </>
  );
}

export default ContributorsPage;
