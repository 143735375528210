import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Justification from './Justification';
import CostCenter from './CostCenter';
import Reason from './Reason';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  AppBar: {
    backgroundColor: '#091c3a',
    zIndex: 0,
    minWidth: '100%'
  }
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <React.Fragment>
      <Grid conatiner>
        <Grid item xs={12}>
          <AppBar position="static" className={classes.AppBar} >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab className={classes.Tab} label={<span style={{ color: 'white', textTransform: 'none' }} >Centro de Custo</span>}  {...a11yProps(0)} />
              <Tab className={classes.Tab} label={<span style={{ color: 'white', textTransform: 'none' }}>Motivo de Viagem</span>}  {...a11yProps(1)} />
              <Tab className={classes.Tab} label={<span style={{ color: 'white', textTransform: 'none' }}>Justificativa</span>}  {...a11yProps(2)} />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      <SwipeableViews
        axis={'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{overflow: 'visible'}}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CostCenter />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Justification />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Reason />
        </TabPanel>
      </SwipeableViews>
    </React.Fragment>
  );
}
