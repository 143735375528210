import { get, patch, post } from 'services/api';

const ContributorService = {}

ContributorService.find = async (params) => {

  return await get({ type: 'user', service: `employee/${params}` })
}

ContributorService.sendEmail = async (params) => {

  return await post({ type: 'user', service: `employee/${params.id}/mail/welcome` })
}

ContributorService.findAll = async (config) => {

  return await get({ type: 'user', service: 'employee', queryString: config })
}

ContributorService.save = async (data) => {

  return await post({ type: 'user', service: 'employee', data: [data] })
}

ContributorService.saveImport = async (data) => {

  return await post({ type: 'user', service: 'employee-import', data: data })
}

ContributorService.edit = async (data) => {

  return await patch({ type: 'user', service: `employee/${data.id}`, data: data })
}

export default ContributorService