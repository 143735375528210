/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';

import { Grid } from '@material-ui/core';
import { TextField, withStyles, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import ListGrid from 'components/organisms/ListGrid';
import CustomizedMenus from 'components/organisms/ListGrid/CustomizedMenus';

import CostCenterService from 'services/cost.center.service';
import './styles.css';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#99a6b7'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#99a6b7'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#99a6b7'
      },
      '&:hover fieldset': {
        borderColor: '#99a6b7'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#16aae7'
      }
    }
  }
})(TextField);

const useStyles = theme => ({
  costInput: {
    width: '256px',
    height: '35px',
    backgroundColor: '#ffffff'
  },
  textFieldInputLabel: {
    opacity: '0.60',
    fontFamily: 'ProximaNova',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#5b616f'
  },

  contentInput: {
    width: '750px',
    height: '35px',
    backgroundColor: '#ffffff',
    textTransform: 'none'
  }
});

function CostCenter(props) {
  const getNewCostCenter = () => ({ name: '', description: '' });

  const { register, handleSubmit, errors } = useForm();
  const [costCenter, setCostCenterState] = useState(getNewCostCenter());
  const [costCenters, setCostCentersState] = useState([]);
  const { classes } = props;

  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    let costCenters = await CostCenterService.findAll();
    setCostCentersState(costCenters);
    setCostCenterState(getNewCostCenter());
  };

  const onChange = (field, event) => {
    let currentValue = costCenter;
    currentValue[field] = event.target.value;
    console.info('onChange costCenter form:', event.target.value);
    setCostCenterState({ ...currentValue });
  };

  const onSave = async () => {
    await CostCenterService.save([costCenter]);
    list();
  };

  const remove = async costCenterId => {
    await CostCenterService.remove(costCenterId);
    list();
  };

  const getDataTable = () => ({
    header: [
      { flex: 2, label: 'Centro de custo' },
      { flexDirection: 'column', label: 'Ações' }
    ],
    rows: getRows(costCenters)
  });

  const getRows = costCenters => {
    return (costCenters || []).reduce((rows, item) => {
      rows.push([
        { flex: 2, label: item.name },
        {
          flexDirection: 'column',
          children: () => (
            <CustomizedMenus id={item.id} items={renderMenuActions(item)} />
          )
        }
      ]);
      return rows;
    }, []);
  };

  const renderMenuActions = row => [
    {
      label: 'Excluir',
      message: `o centro de custo ${row.name}`,
      confirm: true,
      element: () => <Delete />,
      onClick: row => remove(row)
    }
  ];

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <span className="header1">Centro de Custo</span>{' '}
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '1%' }}>
          <span className="content5">
            Adicione os centros de custo da empresa para que o colaborador
            informe no momento da reserva
          </span>
        </Grid>

        <form onSubmit={handleSubmit(onSave)} style={{ display: 'contents' }}>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            <Grid container item xs={12} spacing={4}>
              <Grid item>
                <CssTextField
                  size="small"
                  name="name"
                  className={classes.costInput}
                  value={costCenter.name}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldInputLabel
                    }
                  }}
                  error={errors.name}
                  label="Centro de Custo*"
                  type="text"
                  variant="outlined"
                  onChange={e => onChange('name', e)}
                  inputRef={register({
                    required: {
                      value: true,
                      message:
                        'Preenchimento obrigatório do Nome do centro de custo'
                    }
                  })}
                />
              </Grid>
              <Grid item style={{ width: '75%' }}>
                <CssTextField
                  size="small"
                  style={{ width: '100%' }}
                  name="Description"
                  className={classes.contentInput}
                  value={costCenter.description}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldInputLabel
                    }
                  }}
                  label="Descrição"
                  type="text"
                  onChange={e => onChange('description', e)}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  style={{ backgroundColor: '#308bcb' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>Inserir</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <ListGrid dataTable={getDataTable()} />
    </React.Fragment>
  );
}

export default withStyles(useStyles)(CostCenter);
